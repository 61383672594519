<div class="">

    <div class="row">

        <div class="col-12">
            <div class="config_container">
              <div class="container-title">
                <h3 class="animated fadeIn">Usuario&nbsp;</h3>
                <h3 class="title-desktop animated fadeIn">/ Beneficiarios</h3>
              </div>
              <div class="config_subtitle">Puedes agregar grupos familiares y beneficiarios.
              </div>
              <div class="config_subtitle">
                <!-- <span class="config_update" (click)="openModalCreate()" title="¡Click aqui!">¡Crear nuevo Grupo Familiar!</span> -->
                <hr>

                <div class="row fw_400">
                    <div  *ngIf="numFamiliesLeader==0 && !isLoading" class="col-xl-6 col-md-6 col-xs-12 align_start">
                       Registra tu grupo familiar y agrega a tus beneficiarios.
                    </div>
                    <div *ngIf="numFamiliesLeader>0 && !isLoading" class="col-xl-6 col-md-6 col-xs-12 align_start">
                      Actualmente haces parte de <span class="colorNumber fw_600">&nbsp;{{ familyGroups.length }}&nbsp;</span> grupo<span *ngIf="familyGroups.length!=1">s</span> familiar<span *ngIf="familyGroups.length!=1">es</span>.
                    </div>
                    <div *ngIf="numFamiliesLeader==0 && !isLoading" class="col-xl-6 col-md-6 col-xs-12 align_end">
                      <custom-buttons text="Crear grupo familiar" title="Permite agregar servicio"
                        textColor="#fff" type="button" icon="fa fa-plus" width="250px" (click)="openModalCreate()" />
                    </div>
                </div>
              </div>

            </div>
        </div>
    </div>

    <div *ngIf="familyGroups.length>0 && !isLoading" class="row animated fadeIn">
        <app-card-subservice *ngFor="let group of familyGroups" [infoFamilyGroup]="group" caso="familyGroup"
            (familyAction)="action($event)">
        </app-card-subservice>
    </div>
    <div *ngIf="familyGroups.length==0 && !isLoading" class="animated fadeIn">
      <div class="container-family">
        <div class="cont">
          <app-not-found-data [type]="'notFamily'" [button]="false"  (buttonClicked)="onButtonClicked()" [message]="menssageNotFamily">
          </app-not-found-data>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading" >
        <app-loader [message]="'Cargando grupos familiares...'"></app-loader>
    </div>
</div>
