import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InformationService } from 'src/app/services/information.service';
import { SearchRequest } from '@interfaces/shared-components.interface';
import { SweetAlertService } from '@services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-agreement-template',
  templateUrl: './agreement-template.component.html',
  styleUrls: ['./agreement-template.component.scss']
})
export class AgreementTemplateComponent implements OnInit {
  stylesAgreement:any;

  currentSection = 0;
  sections: any[] = [];  // Cambiamos a una lista dinámica de secciones
  isScrolling = false;
  searchResults: any;
  term: string = '';
  municipalityId: string = '';
  isLoaded : boolean = false;
  videoVisible: boolean = false;
  buttonVisible: boolean = true;

  // Contador de usuarios
  defaultValue: number = 138600;
  usersValueAfter!: number;
  usersValue!: number;
  intervalId: any;

  //Specialities list
  public searchServiceForm!: FormGroup;
  public municipalities: any[] = [];

  videoUrl: any;

  // Array de FAQ
  items: any[] = [
    {
      title: '¿Cómo agendo una cita médica y qué cobertura tienen?',
      description: 'Ingresa en el buscador la especialidad, el nombre del médico o palabra clave del servicio que necesitas, y en el buscador de ubicación ingresa el municipio donde requieres ser atendido; recuerda que tenemos presencia en Pasto, Túquerres, Ipiales, Tumaco, Mocoa, Popayán, Cali y Armenia.',
      isOpen: false
    },
    {
      title: '¿Qué médicos puedo encontrar con ustedes? ',
      description: 'Ofrecemos una amplia gama de servicios de salud, que incluyen consultas con médicos generales y especialistas, exámenes de laboratorio, radiología, procedimientos quirúrgicos y no quirúrgicos y atención médica especializada. Actualmente, contamos con más de 1,650 servicios disponibles a través de nuestra plataforma, lo que te permite acceder a la atención que necesitas para ti y tu familia de manera rápida y eficiente.',
      isOpen: false
    },
    {
      title: '¿Qué debo hacer si tengo problemas para agendar una cita?',
      description: 'Si necesitas ayuda para agendar una cita, puedes comunicarte con nuestro call center, disponible de lunes a viernes en horario de oficina. Estaremos encantados de resolver tus dudas y asistirte en el proceso; simplemente haz clic en el logo de WhatsApp en nuestra página o escríbenos al 3174785093 para obtener asistencia de manera rápida y sencilla.',
      isOpen: false
    },
    {
      title: '¿Los descuentos se aplican a todas las citas médicas?',
      description: 'Sí, los descuentos se aplican a todas las citas que agendes a través de nuestra plataforma para los diferentes servicios de salud con los que tenemos convenio. Recuerda que los descuentos se aplican automáticamente al momento de agendar tu cita, exclusivamente en nuestra plataforma o en la App DESCUENTOS MÉDICOS.',
      isOpen: false
    },
  ];

  // Array de Links Header
  links = [
    { name: 'Inicio' ,
      targetId: 'home'
    },
    { name: 'Descarga',
      targetId:'app'
    },
    { name: 'FAQ',
    targetId:'faq'
    }
  ]
  dataForSections = {
    styles: {
      oRegular: 'Open Sans',
      oSemiBold: 'Open Sans SemiBold',
      oBold: 'Open Sans Bold',
      oExtraBold: 'Open Sans ExtraBold',
      pSemiBold: "'Poppins SemiBold', Open Sans",
      pBold: 'Poppins Bold',
      transicion: 'all ease-in-out 0.3s',
      negro: 'rgb(0, 0, 0)',
      blanco: 'rgb(255, 255, 255)',
      verde: '#018649',
      verde2: 'rgb(127, 178, 46)',
      verde3: '#40a635',
      verde4: 'rgb(233, 243, 227)',
      amarillo: 'rgb(249, 186, 74)',
      amarillo2: 'rgb(254, 240, 200)',
      gris: '#58595b',
      gris2: '#042623',
      gris3: '#dbdcdd',
      gris4: 'rgb(37, 55, 63)',
      gris5: '#9e9e9e',
      gris6: '#d8d8d8',
      gris7: '#989fa5',
      gris8: '#f2f2f2',
      gris9: '#b6bfc6',
      gris10: '#d1e0de',
      deg1: 'linear-gradient(45deg, #018649, #017849)',
      deg2: 'linear-gradient(45deg, #6bd32a, #018649)',
      deg3: 'linear-gradient(45deg, #daf4ca, #bfe1d1)',
      deg4: 'linear-gradient(45deg, #fdc84a, #ffa91d)',
      deg5: 'linear-gradient(45deg, #f2f9f8, #ffffff)',
      shadow: 'rgba(0, 0, 0, 0.04)',
      bgBoton: 'rgba(0, 0, 0, 0.02)',
      bdBoton: 'rgba(232, 232, 232, 0.4)',
      bdFooter: 'rgba(235, 237, 242, 0.9)',
      bgFooter: 'rgba(239, 242, 244, 0.2)',
      facebook: '#3c5994',
      instagram: 'linear-gradient(45deg, #f09433, #e6683c, #dc2743, #dc2743, #bc1888)',
      twitter: '#59a8ea',
      youtube: '#d84e42',
    },
    sections: [
      {
        id: "1",
        title: [
          {
            active: true,
            text: "Haz que cada día sea más feliz"
          },
          {
            active: false,
            text: ""
          }
        ],
        subTitle: [
          {
            active: true,
            text: "Accede a descuentos en salud para ti y tu familia"
          },
          {
            active: false,
            text: ""
          }
        ],
        description: [
          {
            active: true,
            text: "Con Descuentos Médicos App y Coacremat, conseguir médicos y especialistas es más fácil y asequible que nunca."
          },
          {
            active: false,
            text: ""
          }
        ],
        img: [
          {
            active: false,
            path: "https://www.coacremat.coop/Files/Logo/6-horizontal-con-efectos-uso-digital.png"
          },
          {
            active: true,
            path: "../../assets/images/convenios/coacremat/coacremat.png"

          }
        ],
        video: [
          {
            active: false,
            path: ""
          },
          {
            active: false,
            path: ""
          }
        ],
        button: [
          {
            active: false,
            btnName: ""
          },
          {
            active: false,
            btnName: ""
          }
        ],
      },
      {
        id: "2",
        title: [
          {
            active: false,
            text: "La salud es un regalo, no la pongas en riesgo !"
          },
          {
            active: false,
            text: "Cómo trabaja descuentos médicos para ti?"
          }
        ],
        subTitle: [
          {
            active: false,
            text: ""
          },
          {
            active: true,
            text: "Elige el servicio, el médico y el descuento que más te convenga."
          }
        ],
        description: [
          {
            active: false,
            text: "¡Conseguir una cita médica particular con descuento, rápida y con trato preferencial nunca ha sido tan fácil!  En nuestra plataforma, puedes acceder a atención de calidad con los mejores médicos de la ciudad Agenda tu cita en tiempo real y aprovecha tarifas preferenciales. Obtén descuentos exclusivos del 10%, 20%, 30%… ¡hasta un 50% en tus citas médicas particulares!"

          },
          {
            active: true,
            text: "¡Tu salud es nuestra prioridad! Accede a más de 350 médicos y especialistas y más de 1,650 servicios en salud. Agenda tus citas médicas particulares de forma rápida y eficiente. Solicita tus citas en tiempo real desde nuestra web o app, disponible 24/7"

          }
        ],
        img: [
          {
            active: true,
            path: "https://www.descuentosmedicos.co/assets/LANDING%20GENERATOR/FOTO%20JEFE-18.png"
          },
          {
            active: true,
            path: "../../assets/images/logo_black_font.png"
          }
        ],
        video: [
          {
            active: false,
            path: ""
          },
          {
            active: false,
            path: ""
          }
        ],
        button: [
          {
            active: false,
            btnName: ""
          },
          {
            active: false,
            btnName: ""
          }
        ],
      },
      {
        id: "3",
        title: [
          {
            active: false,
            text: "La salud es un regalo, no la pongas en riesgo"
          },
          {
            active: false,
            text: ""
          }
        ],
        subTitle: [
          {
            active: true,
            text: "La salud es un regalo, no la pongas en riesgo"
          },
          {
            active: false,
            text: ""
          }
        ],
        description: [
          {
            active: true,
            text: "Agenda tus citas médicas sin largas esperas, sin llamadas y en tiempo real, obtén descuentos exclusivos del 10%, 20%, 30%… ¡hasta un 50% en tus citas médicas particulares!"
          },
          {
            active: false,
            text: "Obtén descuentos exclusivos del 10%, 20%, 30%… ¡hasta un 50% en tus citas médicas particulares!"
          }
        ],
        img: [
          {
            active: true,
            path: "https://www.descuentosmedicos.co/assets/LANDING%20GENERATOR/doctor-ficti-2.png"
          },
          {
            active: false,
            path: "https://via.placeholder.com/500"
          }
        ],
        video: [
          {
            active: false,
            path: ""
          },
          {
            active: true,
            path: "https://www.youtube.com/embed/4K98CzmgQYI"
          }
        ],
        button: [
          {
            active: false,
            btnName: ""
          },
          {
            active: false,
            btnName: ""
          }
        ],
      },
    ]
  };

  options: AnimationOptions = {
    path: 'assets/lottie-animations/doctor.json',
  };

  //Doctors
  doctors = [
    {
      img: 'https://i.pravatar.cc/150?img=1',
      name: 'Descuentos exclusivos: ',
      specialty: 'Accede a tarifas preferenciales en citas médicas particulares con los mejores médicos de la ciudad, para ti y hasta 10 miembros de tu familia.',
    },
    {
      img: 'https://i.pravatar.cc/150?img=2',
      name: 'Sin costos de suscripción',
      specialty: 'No hay tarifas anuales ni mensuales; solo pagas el precio de la cita aplicando el descuento al momento de agendar.',
    },
    {
      img: 'https://i.pravatar.cc/150?img=1',
      name: 'Amplia red de especialistas',
      specialty: 'Con más de 350 médicos y más de 1,650 servicios en salud disponibles, garantizamos atención de calidad y eficiencia.',
    },
    {
      img: 'https://i.pravatar.cc/150?img=2',
      name: 'Acceso rápido y eficiente',
      specialty: 'Agenda tus citas de forma sencilla y rápida a través de nuestra web o app, accesible las 24 horas del día, los 7 días de la semana.',
    },
    {
      img: 'https://i.pravatar.cc/150?img=7',
      name: 'Cuidado familiar prioritario',
      specialty: 'Porque cuidar de tu salud y la de tus seres queridos es lo más importante, te ofrecemos soluciones accesibles para que no tengas que esperar.',
    },
    {
      img: 'https://i.pravatar.cc/150?img=8',
      name: 'Atención veterinaria',
      specialty: 'Ofrecemos acceso a servicios de salud para tus compañeros peludos, asegurando que reciban la atención que merecen.',
    },

  ];

  constructor(
    private infoService: InformationService,
    private router: Router,
    private swalService: SweetAlertService,
    private sanitizer: DomSanitizer,

  ){

    this.inicializarCompanyForm();

    this.searchServiceForm = new FormGroup({
      municipality: new FormControl(''),
      searchData: new FormControl(''),
    });
  }

  ngOnInit() {
    // Cargamos las secciones desde el JSON dinámico
    this.sections = this.dataForSections.sections;
    this.stylesAgreement = this.dataForSections.styles;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.sections[2].video[1].path);


    this.goToSection(this.currentSection);
    this.setupPaginationClick();
    this.iniciarData();

    this.videoVisible = false;
    this.buttonVisible = true;
  }
  // Método para obtener la URL segura

  getSafeUrl(videoUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  volverArriba() {
    let posicion = window.pageYOffset;
    const intervalId = setInterval(() => {
      window.scrollTo({ top: posicion, behavior: 'auto' });
      if (posicion > 0) {
        posicion -= 30; // ajusta este número para cambiar la velocidad de la animación
      } else {
        clearInterval(intervalId);
      }
    }, 10); // este número está relacionado con la frecuencia de actualización de los navegadores (60Hz = 16.67ms por fotograma)
  }

  abrirWhatsApp() {
    const url = 'https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20interesa%20saber%20de%20la%20aplicación';
    window.open(url, '_blank');
  }

  setupPaginationClick() {
    const paginationItems = document.querySelectorAll('.pagination li');
    paginationItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        this.currentSection = index;
        this.goToSection(this.currentSection);
      });
    });
  }

  @HostListener('window:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (!this.isScrolling) {
      this.isScrolling = true;

      setTimeout(() => {
        this.isScrolling = false;
      }, 300);

      if (event.deltaY > 0) {
        this.nextSection();
      } else {
        this.previousSection();
      }
    }
  }

  nextSection() {
    if (this.currentSection < this.sections.length - 1) {
      this.currentSection++;
      this.goToSection(this.currentSection);
    }
  }

  previousSection() {
    if (this.currentSection > 0) {
      this.currentSection--;
      this.goToSection(this.currentSection);
    }
  }

  goToSection(index: number) {
    let element = document.getElementById('section' + this.sections[index].id);
    if (element) {
      let topOffset = element.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth'
      });
      this.updatePagination();
    }
  }

  updatePagination() {
    let paginationItems = document.querySelectorAll('.pagination li');
    paginationItems.forEach((item, index) => {
      if (index === this.currentSection) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }



  iniciarData() {
    this.infoService
      .getMunicipalities()
      .then((resp: any) => {
        this.municipalities = resp;
        ////console.log(this.municipalities);
      })
      .catch(() => {
        this.municipalities = [];
      });
  }

  inicializarCompanyForm() {
    this.searchServiceForm = new FormGroup({
      municipality: new FormControl('', Validators.required),
      searchData: new FormControl('', Validators.required),
    });
  }

  searchSubservices() {
    let municipalityControl = this.searchServiceForm.get('municipality');
    let municipalityValue = municipalityControl
      ? municipalityControl.value
      : null;

    let searchDataControl = this.searchServiceForm.get('searchData');
    let searchDataValue = searchDataControl ? searchDataControl.value : null;

    if (municipalityValue && searchDataValue) {

      const searchRequest: SearchRequest = {
        municipalityId: municipalityValue._id,
        term: searchDataValue,
        filters: {
          price: {
            enabled: false,
            min: 0,
            max: 0,
          },
          provider: true,
          doctor: true,
          services: {
            pets: true,
            humans: true,
          },
        },
      };

      this.infoService.search(searchRequest).subscribe(
        (searchResults: any) => {

          if (searchResults.body || searchResults.ok == true) {
            let mun = municipalityValue._id;
            let search = searchDataValue;
            let city =  municipalityValue.municipality;
            this.isLoaded = true;

            this.router.navigate(['/directory'], {
              queryParams: {
                mun,
                city: JSON.stringify(city),
                search
              },
            });
          }
          else{
            this.isLoaded = false;

          }
        },
        (searchError: any) => {
          console.error('Error en la búsqueda:', searchError);
        }
      );
    } else {
      this.swalService.lauchSwal(
        '',
        'Ingresa tu ubicación y busqueda <br> (especialidad, médico o afección)',
        'warning'
      );
    }
  }

  toggleItem(index: number): void {
    this.items[index].isOpen = !this.items[index].isOpen;

    this.items.forEach((item, i) => {
      if (i !== index) item.isOpen = false;
    });
  }
}
