<div *ngIf="familyGroup" class="config_title mb-2">Beneficiarios, {{familyGroup.name }}</div>

<div style="padding: 10px;">
  <div class="tab-content" id="pills-tabContent">

    <div class="animated fadeIn">
      <ng-container [ngTemplateOutlet]="currentView"></ng-container>
    </div>

  </div>
</div>


<ng-template #beneficiariesList>


  <div class="row fw_400">
      <div class="col-xl-6 col-md-6 col-xs-12 fw_500 align_start">
        <span class="config_campos_number">Has registrado {{beneficiaries.length - 1}} Beneficiario<span *ngIf="beneficiaries.length>1">s</span></span>.
      </div>
      <custom-buttons
        class="col-xl-6 col-md-6 col-xs-12 align_end"
        text="Agregar beneficiario"
        title="Permite agregar beneficiario"
        textColor="#fff"
        type="button"
        icon="fa fa-plus" width="250px"
        (click)="iniciarFindUserForm()"
      />
  </div>

  <div class="table-responsive no-wrap mt-4">
    <table *ngIf="beneficiaries.length>0" class="table vm no-th-brd pro-of-month">
      <thead>
        <tr>
          <th colspan="3">Beneficiario</th>
          <th *ngIf="options">Opciones</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let beneficiary of beneficiaries">
          <tr class="pointer row_branch fw_500">
            <td>
              <span class="round img_branch" (click)="selectBeneficiary(beneficiary)">
                <img [src]="beneficiary.avatar|getImage" alt="user" width="50">
              </span>
            </td>

            <td (click)="selectBeneficiary(beneficiary)">
              <div class="sucursal_name">{{beneficiary?.names}} {{beneficiary?.lastName}}</div>
            </td>
            <td class="fz_20">
              <div *ngIf="beneficiary.familyLeader"><span class="label label-success label-rounded">Titular</span></div>
              <div *ngIf="!beneficiary.familyLeader"><span
                  class="label label-info label-rounded">{{getRelationshipName(beneficiary.relationship||'')}}</span>
              </div>
            </td>
            <td>
              <div *ngIf="options" class="actions">
                <i *ngIf="beneficiary.familyLeader" class="fa fa-info-circle info"
                  title="Titular del Grupo Familiar"></i>
                <!-- <i *ngIf="!beneficiary.familyLeader" class="fa fa-lock disabled" title="Desactivar"></i>                 -->
                <i *ngIf="!beneficiary.familyLeader" class="fa fa-trash delete" title="Eliminar"
                  (click)="deleteBeneficiaryId(beneficiary)"></i>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <table *ngIf="beneficiaries.length==0" class="table vm no-th-brd pro-of-month">
      <thead>
        <tr>
          <th class="no_registrados">No hay Beneficiarios registrados</th>
        </tr>
      </thead>
    </table>
  </div>
</ng-template>

<ng-template #beneficiaryEdit>
  <hr>
  <div class="branch_container animated fadeIn">
    <div class="card-body">
      <div class="update_settings_header mb-4">
        <!-- <button type="button" class="btn btn-circle btn_back" title="Regresar" (click)="regresarBeneficiaries()"><i
            class="ti ti-arrow-left"></i> </button> -->

        <custom-buttons type="button" style="padding-right: 10px;" (click)="regresarBeneficiaries()" text=""
          textColor="#fff" [title]="'Regresar'" icon="ti ti-arrow-left">
        </custom-buttons>
        <div class="settings_header_title">Agregar Beneficiario al Grupo Familiar</div>
      </div>

      <form [formGroup]="findUserForm" class="mb-4">
        <app-form-section [number]="'1'" [sectionName]="'Registrar número de Documento'"></app-form-section>
        <div class="row">
          <div class="col-12 mt-2">
            <div class="container mb-4 fw_400">
              Por favor, registra el número de documento de tu beneficiario y presiona el botón de buscar para agregarlo
              a tu grupo familiar. Gracias.
            </div>
            <app-search-input [placeholderInput]="'Buscar Beneficiario'" [microphone]="false"
              [regExValidate]="regExValidate" (searchValueEmmiter)="searchValueEmmiter($event)"
              (searchErrorEmmiter)="searchErrorEmmiter($event)">
            </app-search-input>
          </div>


          <div id="idInfoFind" class="col-12 mt-4">
            <ng-container *ngIf="findUser ==''">
              <div class="no_registrados animated fadeIn" style="padding: 15px;">!Busca a tu Beneficiario para
                continuar¡</div>
            </ng-container>

            <ng-container *ngIf="findUser =='encontrado'">

              <div *ngIf="solicitudEnviada" class="container mb-4 fw_500 si_registrados">Solicitud enviada</div>
              <div *ngIf="!solicitudEnviada">
                <div class="container mb-4 fw_500 si_registrados">
                  Hemos encontrado un usuario con este número de documento. Si deseas agregarlo a tu grupo familiar, por
                  favor envía una solicitud.
                </div>
                <form id="idFormRequest" [formGroup]="sendRequestForm" class="mt-4 animated fadeIn">

                  <div class="row align_buttons">
                    <app-form-section [number]="'2'" [sectionName]="'Parentezco con el Titular'"></app-form-section>
                    <div class="container mb-2 fw_400">
                      Seleccione el parentesco que el beneficiario tiene con el Titular del grupo.
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <form-select [id]="'idInputRelationship'" helper="Selecciona un parentesco de la lista"
                          label="Parentesco" placeholder="Seleccione..." [dataList]="relationShips"
                          [controlName]="'relationship'" [idField]="'value'" [valueField]="'name'">
                        </form-select>
                      </div>
                    </div>
                    <div class="col-12" style="display: flex;
                                        justify-content: center;">
                      <custom-buttons text="Enviar solicitud" title="permite agregar" textColor="#fff"
                        icon="ti-plus ml-2" type="button" (click)="sendRequestFamily(sendRequestForm)" />
                    </div>
                  </div>

                </form>
              </div>



            </ng-container>

            <ng-container *ngIf="findUser =='noEncontrado' && !agregateConfirm">
              <div class="no_registrados animated fadeIn user_no_encontrado">
                <div>
                  !Usuario No Encontrado¡
                </div>
                <span>No se encontro a tu beneficiario, deseas registrarlo?</span>
                <!-- <button type="button" class="btn btn-rounded btn-block btn-primary mt-2"
                  (click)="agregateBeneficiaryConfirm()">Si, Agregar nuevo usuario</button> -->

                <custom-buttons type="button" class="mt-2" (click)="agregateBeneficiaryConfirm()"
                  text="Si, Agregar nuevo usuario" textColor="#fff" [title]="''" [rounded]="true">
                </custom-buttons>
              </div>
            </ng-container>

            <ng-container *ngIf="findUser =='error'">
              <div class="no_registrados animated fadeIn user_no_encontrado user_error" style="padding: 20px;">
                <div>
                  ! Error ¡
                </div>
                <span>Debes registrar el numero de documento de tu Beneficiario para poder buscarlo¡</span>
              </div>
            </ng-container>

          </div>

        </div>

      </form>


      <ng-container *ngIf="agregateConfirm">
        <form id="idFormBeneficiary" [formGroup]="beneficiaryForm" class="mt-4 animated fadeIn">

          <app-form-section [number]="'2'" [sectionName]="'Datos del Beneficiario'"></app-form-section>

          <div class="row">

            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-select [id]="'idInputTipoDocumento'" helper="Selecciona un tipo de documento de la lista"
                  label="Tipo documento" placeholder="Seleccione..." [dataList]="typesOfDocument"
                  [controlName]="'tipoDocumento'" [idField]="'type'" [valueField]="'type'"></form-select>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-input [id]="'idInputNumeroDocumento'"
                  helper="Para modificar el número de documento vuelve a Buscar un Beneficiario" label="Documento"
                  placeholder="número documento" [controlName]="'numeroDocumento'"></form-input>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-input [id]="'idInputNombres'" helper="" label="Nombres"
                  placeholder="Escribre los nombres del beneficiario" [controlName]="'nombres'">
                </form-input>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-input [id]="'idInputApellidos'" helper="" label="Apellidos"
                  placeholder="Escribre los apellidos del beneficiario" [controlName]="'apellidos'">
                </form-input>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-date [id]="'idInputFechaNacimiento'" label="Fecha de Nacimiento" [maxNow]="true"
                  [maxInput]="maxDate" [controlName]="'fechaNacimiento'"></form-date>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-input [id]="'idInputYears'"
                  helper="Si el beneficiario es mayor de 18 años, debes seleccionar sus permisos" [required]="false"
                  label="Edad Actual" placeholder="Edad en años" [controlName]="'years'"></form-input>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">

                <!-- <form-phone [id]="'idInputTelefono'" [controlName]="'telefono'"
                  label="Número de telefono"></form-phone> -->
                  <form-input [id]="'idInputTelefono'" helper="" label="Número celular del paciente"
                  placeholder="Registre el telefono del paciente" [controlName]="'telefono'" [customErrors]="{
                    required: 'El número de teléfono es obligatorio',
                    pattern: 'El número debe empezar con 3 y tener exactamente 10 dígitos'
                  }">
                </form-input>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-xs-12">
              <div class="form-group">
                <form-email [id]="'idInputCorreo'" helper="" [required]="false" label="Correo electrónico"
                  placeholder="ejemplo@mail.com" [controlName]="'correo'">
                </form-email>
              </div>
            </div>

          </div>

          <app-form-section [number]="'3'" [sectionName]="'Parentezco con el Titular'"></app-form-section>
          <div class="container mb-2 fw_400">
            Seleccione el parentesco que el beneficiario tiene con el Titular del grupo.
          </div>
          <div class="col-12">
            <div class="form-group">
              <form-select [id]="'idInputRelationship'" helper="Selecciona un parentesco de la lista" label="Parentesco"
                placeholder="Seleccione..." [dataList]="relationShips" [controlName]="'relationship'"
                [idField]="'value'" [valueField]="'name'">
              </form-select>
            </div>
          </div>

          <ng-container *ngIf="edadActual>=18">

            <app-form-section class="animated fadeIn" [number]="'4'"
              [sectionName]="'Permisos del Beneficiario'"></app-form-section>

            <form-checks [controlName]="'checks'" [data]="dataChecks" [required]="false"
              label="Selecciona los permisos que va a tener tu beneficiario" [colClass]="'col-md-4 col-xs-12'">
            </form-checks>

          </ng-container>

          <div class="settings_btn mt-4">
            <button type="button" (click)="agregateBeneficiary(beneficiaryForm)"
              class="btn btn-success btn-rounded">Agregar Beneficiario</button>

            <!-- <custom-buttons type="button" (click)="agregateBeneficiary(beneficiaryForm)"
              class="btn btn-success btn-rounded" text="Agregar Beneficiario" textColor="#fff" [title]="''">
            </custom-buttons> -->
          </div>


        </form>
      </ng-container>
    </div>
  </div>

</ng-template>
