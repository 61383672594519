<!--

<div class="container-template">
  <section *ngFor="let section of sections; let i = index" class="section landing-section" [id]="'section' + section.id">

    <div class="row">
      <div class="col-lg-6 content-principal">
        <div class="content-templates text-center">
          <div *ngIf="section.img?.[0]?.active" class="image-container">
            <img [src]="section.img[0].path" alt="Image for {{ section.title[0]?.text }}" />
          </div>
          <h1 class="title-mobile" *ngIf="section.title?.[0]?.active"
          [ngStyle]="{
            'color': stylesAgreement.verde,
            'font-family': stylesAgreement.pSemiBold,
            }"
          >{{ section.title[0]?.text }}</h1>
          <h2 class="subtitle-mobile" *ngIf="section.subTitle?.[0]?.active"
          [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.oRegular,
            }"
            >{{ section.subTitle[0]?.text }}</h2>
          <p class="description-mobile" *ngIf="section.description?.[0]?.active"
          [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.oRegular,
            }"
            >{{ section.description[0]?.text }}</p>

            <div *ngIf="section.video?.[0]?.active" class="video-container">
              <iframe class="video-youtube"
                [src]="getSafeUrl(section.video[0]?.path)"
                loading="lazy"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </div>

          <div *ngIf="section.button?.length > 0" class="buttons">
            <ng-container *ngFor="let button of section.button">
              <button *ngIf="button.active" class="btn-primary">{{ button.btnName }}</button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-lg-6 content-principal"  *ngIf="section">
        <div class="content-templates text-center">
          <div *ngIf="section.img?.[1]?.active" class="image-container">
            <img [src]="section.img[1].path" alt="Image for {{ section.title[1]?.text }}" />
          </div>
          <h1 class="title-mobile" *ngIf="section.title?.[1]?.active"
          [ngStyle]="{
            'color': stylesAgreement.verde,
            'font-family': stylesAgreement.pSemiBold,
            }"
          >{{ section.title[1]?.text }}</h1>
          <h2 class="subtitle-mobile" *ngIf="section.subTitle?.[1]?.active"
          [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.oRegular,
            }"
            >{{ section.subTitle[1]?.text }}</h2>
          <p class="description-mobile"*ngIf="section.description?.[1]?.active"
          [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.oRegular,
            }"
            >{{ section.description[1]?.text }}</p>

            <div *ngIf="i == 2" class="video-container">
              <iframe
                class="video-youtube"
                loading="lazy"
                [src]="videoUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </div>

          <div *ngIf="section.button?.length > 0" class="buttons">
            <ng-container *ngFor="let button of section.button">
              <button *ngIf="button.active" class="btn-primary">{{ button.btnName }}</button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-lg-12" *ngIf="i == 0">
        <div class="down-btn animated fadeInDown form_container">
          <form [formGroup]="searchServiceForm" (ngSubmit)="searchSubservices()">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
                <form-input helper="" label="Especialidad, médico o afección:" [required]="false" labelColor="#fff"
                  placeholder="Buscar..." [controlName]="'searchData'" [type]="'text'"></form-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
                <form-autocomplete label="¿En que ciudad quieres tu cita?" labelColor="#fff" [required]="false"
                  [placeholder]="'Busca tu departamento o municipio...'" [dataList]="municipalities"
                  [controlName]="'municipality'" [idField]="'_id'" [valueField]="'municipality'">
                </form-autocomplete>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 text-center btn_align mt-4 pt-md-3">
                <custom-buttons text="Busca tu cita" title="permite buscar tus servicios" textColor="#fff"
                  icon="ti-search ml-2" height="42px" width="250px" [isLoading]="isLoaded" type="submit"
                  class="button-search" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </section>

  <div class="pagination">
    <ul>
      <li *ngFor="let section of sections; let i = index" [class.active]="i === currentSection" (click)="goToSection(i)"></li>
    </ul>
  </div>
</div> -->


<!-- ============================================================== -->
<!-- Page wrapper  -->
<!-- ============================================================== -->
<div class="page-wrapper">
  <!-- ============================================================== -->
  <!-- Container fluid  -->
  <!-- ============================================================== -->
  <div class="container-fluid-temp">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="fix-width">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-12 container-agreement">
          <div class="text-center animated bounceInUp">
            <img class="img-logo" [src]="sections[0].img[1]?.path" />
          </div>
          <div>
          </div>
          <h1 class="text-center title-mobile animated bounceInUp" [ngStyle]="{
            'color': stylesAgreement.verde,
            'font-family': stylesAgreement.pSemiBold,
            }">
            {{ sections[0].title[0]?.text }}
          </h1>
          <h2 class="text-center subtitle-mobile animated bounceInUp" [ngStyle]="{
          'color': stylesAgreement.verde,
          'font-family': stylesAgreement.pSemiBold,
          }">
            {{ sections[0].subTitle[0]?.text }}
          </h2>
          <p class="text-center description-mobile animated bounceInUp" [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.oRegular,
            }">
            {{ sections[0].description[0]?.text }}
          </p>

        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 lottie-container">
          <div class=" text-center lottie-class animated bounceInUp">
            <div class="text-center" style="height: 58vh;">
              <ng-lottie [options]="options"></ng-lottie>
            </div>
          </div>
        </div>
      </div>

      <!-- form -->

      <!-- form -->
      <div class="down-btn animated bounceInUp form_container">
        <form [formGroup]="searchServiceForm" (ngSubmit)="searchSubservices()">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-autocomplete label="¿En que ciudad quieres tu cita?" labelColor="#fff" [required]="false"
                [placeholder]="'Busca tu departamento o municipio...'" [dataList]="municipalities"
                [controlName]="'municipality'" [idField]="'_id'" [valueField]="'municipality'">
              </form-autocomplete>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-input helper="" label="Especialidad, médico o afección:" [required]="false" labelColor="#fff"
                placeholder="Buscar..." [controlName]="'searchData'" [type]="'text'"></form-input>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 text-center btn_align mt-4">
              <button class="nav-link btn-search btn-block btn_size font-medium text-white" type="submit">
                Busca tu cita <i class="ti-search ml-2"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- form -->

      <!-- form -->

      <!-- ============================================================== -->
      <!-- Doctors Section -->
      <!-- ============================================================== -->
      <div style="margin-top: 40px;">
        <h1 class="text-center subtitle-mobile " [ngStyle]="{
        'color': stylesAgreement.verde,
        'font-family': stylesAgreement.pSemiBold,
        }">
          Nuestros beneficios:
        </h1>
        <div class="container_doctors text-center">
          <div *ngFor="let doctor of doctors">
            <img [src]="doctor.img" alt="" />
            <h5 class="mt-5" style="font-weight: 700;">{{ doctor.name }}</h5>
            <small class="text-white mt-5">{{ doctor.specialty }}</small>
          </div>
        </div>
      </div>
    </div>

    <!-- ============================================================== -->
    <!-- Featured Section -->
    <!-- ============================================================== -->
    <div class="row page-wrapper2">
      <div class="col-md-12" id="demos">
        <div class="max-width">
          <section class="section overfree">
            <div class="">
              <div class="section-descr text-center">
                <h1 class="font-bold text-white">
                  {{ sections[1].title[0]?.text }}
                </h1>
                <hr />
                <p class="text-center text-white description-mobile animated bounceInUp" [ngStyle]="{
                'font-family': stylesAgreement.oRegular,
                }">
                  {{ sections[1].description[0]?.text }}
                </p>
              </div>
            </div>
            <div class="text-center">
              <iframe class="video-youtube" loading="lazy" [src]="videoUrl" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
            </div>
            <!-- end container -->
          </section>
          <section class="section overfree">
            <div class="space_median">
              <div class="section-descr text-center">
                <h1 class="font-bold text-white">
                  {{ sections[1].title[1]?.text }}
                </h1>
                <hr />
                <p class="text-center text-white description-mobile animated bounceInUp" [ngStyle]="{
                'font-family': stylesAgreement.oRegular,
                }">
                  {{ sections[1].description[1]?.text }}
                </p>
              </div>

              <div class="row custom-features">
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-line-chart"></i> Escalamiento
                    </h3>
                    <span class="font-medium text-white">
                      Nuestra plataforma está diseñada para crecer de manera continua. A medida que expandimos nuestras
                      alianzas a lo largo del país, garantizamos que un número creciente de usuarios tenga acceso a
                      atención médica particular de calidad.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-search"></i> Selección de profesionales.
                    </h3>
                    <span class="font-medium text-white">
                      En Descuentos Médicos, Llevamos a cabo una selección rigurosa de los profesionales de la salud,
                      asegurando que solo aquellos habilitados por el Ministerio de Salud ReTHUS formen parte de nuestra
                      red. Esto te brinda la confianza necesaria al buscar atención médica particular.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-mobile"></i> Fácil de Usar:
                    </h3>
                    <span class="font-medium text-white">
                      Hemos simplificado el proceso de agendamiento de citas médicas particulares, eliminando
                      complicaciones y la necesidad de realizar llamadas. Todo se puede hacer en tiempo real desde la
                      comodidad de tu computadora o celular.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-folder-open-o"></i> Actualizaciones
                      Constantes
                    </h3>
                    <span class="font-medium text-white">
                      Nuestra plataforma se actualiza continuamente con la información más reciente sobre médicos,
                      servicios y descuentos, garantizando así una experiencia de usuario óptima.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-star"></i> Descuentos Exclusivos
                    </h3>
                    <span class="font-medium text-white">
                      Gracias a nuestros convenios con los mejores médicos y especialistas, tienes acceso a tarifas
                      preferenciales que hacen que la atención médica particular sea más asequible para ti y tu familia.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-code"></i> Soporte y Asesoramiento
                    </h3>
                    <span class="font-medium text-white">
                      Nuestro equipo de atención al cliente está disponible en horario de oficina para brindarte el
                      soporte y asesoramiento que necesites. Ya sea para agendar citas, resolver dudas o mejorar tu
                      experiencia con Descuentos Médicos, estamos aquí para asegurarnos de que tu atención sea
                      excepcional.
                    </span>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end container -->
          </section>
          <!-- end section -->
          <section id="faq" class="frecuentes">
            <div class="section-descr text-center">
              <h1 class="font-bold text-white">
                Preguntas frecuentes
              </h1> <br>
              <div class="accordion">
                <div class="accordion-content" *ngFor="let item of items; let i = index">
                  <div (click)="toggleItem(i)" class="prevenir-preguntas">
                    <header>
                      <span class="title">{{ item.title }}</span>
                      <i class="fa" [class]="item.isOpen ? 'fa-solid fa-minus' : 'fa-solid fa-plus'"></i>
                    </header>
                    <p class="description" [class.open]="item.isOpen">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!-- ============================================================== -->
    <!-- Download app Section -->
    <!-- ============================================================== -->
    <div class="fix-width">
      <div class="row">
        <div class="col-lg-5 py-5">
          <div class="text-center">
            <img src="./assets/images/phones/handApp.png" class="img-logo" />
          </div>
        </div>
        <div class="col-lg-7 py-5 text-center">
          <img class="img-logo" src="./assets/images/logo_black_font.png" alt="logo" />
          <br />
          <h2 class="text-center subtitle-mobile" [ngStyle]="{
            'color': stylesAgreement.gris,
            'font-family': stylesAgreement.pSemiBold,
            }">
            Descarga la App y empieza a ahorrar ahora !
          </h2>
          <br />
          <a href="https://apps.apple.com/co/app/prevenir-descuentos-m%C3%A9dicos/id1560589182"
            title="Descargar desde Apple Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/ios.png" style="height: 60px; width: 200px" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.prevenirdescuentosmedicos.app"
            title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/android.png" style="height: 60px; width: 200px" />
          </a>
          <a href="https://appgallery.cloud.huawei.com/ag/n/app/C104125813?locale=es_US&source=appshare&subsource=C104125813"
            title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/gallery.png" style="height: 60px; width: 200px" />
          </a>
        </div>
      </div>
    </div>



    <!-- ============================================================== -->
    <!-- Back up button -->
    <!-- ============================================================== -->

    <button class="bt-wpp btn btn-circle btn-lg btn-success" type="button" (click)="abrirWhatsApp()">
      <i class="font-bold fa fa-whatsapp"></i>
    </button>

    <button class="bt-top btn btn-circle btn-lg btn-success" type="button" (click)="volverArriba()">
      <i class="font-bold fa fa-chevron-up"></i>
    </button>


  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
</div>
<app-landing-footer></app-landing-footer>
