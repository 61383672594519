<div class="card-body" [style.margin-top]="view === 'register' ? '4rem!important' : '0rem!important'">
    <ng-container [ngTemplateOutlet]="view === 'register' ? register : registerForm"></ng-container>
</div>

<!-- ============================================================== -->
<!-- Templates -->
<!-- ============================================================== -->
<ng-template #register>
  <div class="animated fadeInUp text-center">
    <a href="/" class="text-center db">
      <!-- <img
      src="../../../assets/images/logo-icon.png"
      alt="Home" /><br /><img
      src="../../../assets/images/logo-text.png"
      alt="Home"/> -->
      <img src="./assets/images/logo_black_font.png" alt="logo" class="img-logo-auth" />
    </a>

    <div class="text-center">
      <h2 class="box-title m-t-40 m-b-0">Regístrate ahora</h2>
      <span>Crea tu cuenta y disfruta los beneficios que descuentos médicos tiene para tí</span>
      <h5 class="mt-5 mb-3">Registrate con Google</h5>
      <div class="ml-4"> <asl-google-signin-button type="icon" size="large"></asl-google-signin-button></div>
      <h5 class="mt-3 mb-3">O</h5>
      <custom-buttons text="Crear cuenta" title="permite crear cuenta" textColor="#fff" icon="ti-plus ml-2"
        width="250px" type="button" (click)="viewChange('registerForm')" />
    </div>

    <div class="form-group m-b-0 mt-5">
      <div class="col-sm-12 text-center">
        <p>
          ¿Ya tienes una cuenta?
          <a routerLink="/auth/login" class="text-info m-l-5"><b class="text_defaultnm">Iniciar sesión</b></a>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registerForm>
  <div class="animated fadeInUp">
    <div class="container-button">
      <div class="col-2 align_start">
        <custom-buttons (click)="viewChange('register')" class="" text="" textColor="#fff" type="button"
          icon="mdi mdi-arrow-left" [title]="''" textSize="20px">
        </custom-buttons>
      </div>
      <div class="col-10" style="right: 35px;">
        <h2 class="box-title ml-4 align_start" >Crear cuenta</h2>
      </div>
    </div>
    <br>
    <small>Crea una cuenta con los datos necesarios para sacar una cita y disfrutar
      de los beneficios que los especialistas tienen para tí.</small>

    <div class="mt-2">
      <form
        ngNativeValidate
        [formGroup]="registerUserForm"
        (ngSubmit)="registerByForm()"
      >
      <div class="mt-4">
        <form-input
          helper=""
          label="Nombres"
          placeholder="Escribe los nombres"
          [controlName]="'nombres'"
          [type]="'text'"
        ></form-input>
      </div>
      <div class="mt-4">
        <form-input
          helper=""
          label="Apellidos"
          placeholder="Escribe los apeliidos"
          [controlName]="'apellidos'"
          [type]="'text'"
        ></form-input>
      </div>
      <div class="mt-4">
        <form-date
          label="Fecha de nacimiento"
          [minInput]="min"
          [maxInput]="max"
          controlName="fechaNacimiento"
        ></form-date>
      </div>
      <div class="mt-4">
        <form-email
          helper=""
          label="Correo electronico"
          placeholder="Escribe el correo"
          [controlName]="'correo'">
        </form-email>
      </div>
      <div class="mt-4">
        <form-document
          helper=""
          label="Número de Documento"
          filter="Adult"
          [controlName]="'tipoDocumento'"
          placeholder="Seleccione..."
        ></form-document>
      </div>
      <div class="mt-4">
        <form-input
          helper=""
          label="Número de Telefono"
          placeholder="Escribe el telefono"
          [controlName]="'telefono'"
          [type]="'text'"
        ></form-input>
      </div>
      <div class="mt-4">
        <form-password
          helper=""
          label="Contraseña"
          placeholder="Escribe la contraseña"
          [controlName]="'contrasena'"
        ></form-password>
      </div>

        <div class="checkbox checkbox-success mt-5">
          <input id="checkbox-signup" type="checkbox" class="chk-col-teal material-inputs" formControlName="terms">
          <label for="checkbox-signup" class=""> Acepto los <a href="/terms" target="_blank"> Terminos y condiciones </a></label>
        </div>

        <div class="align_buttons mt-2">

          <custom-buttons
              text="Cancelar"
              title="Cancelar registro"
              width="120px"
              type="button"
              [inputBackgroundColor]="'danger'"
              (click)="viewChange('register')"
            >
          </custom-buttons>

          <custom-buttons
              text="Registrar"
              title="Registrar usuario"
              width="120px"
              type="submit"
            >
          </custom-buttons>

        </div>
      </form>
    </div>
  </div>
</ng-template>
