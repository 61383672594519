import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { CellPhonesListComponent } from './components/cell-phones-list/cell-phones-list.component';
import { DirectivesModule } from '../directives/directives.module';
import { TipoEmpresaComponent } from './components/tipo-empresa/tipo-empresa.component';
import { VerAyudasComponent } from './components/ver-ayudas/ver-ayudas.component';
import { CardComponent } from './components/card/card.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ChangeImageComponent } from './change-image/change-image.component';
import { AlbumsComponent } from './albums/albums.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SpecialtyListComponent } from './components/specialty-list/specialty-list.component';
import { StudiesListComponent } from './components/studies-list/studies-list.component';
import { ExperienceListComponent } from './components/experience-list/experience-list.component';
import { CustomFormModule } from '../custom-forms/custom-form.module';
import { ReusableModalModule } from '../reusable-modal/reusable-modal.module';
import { TabsComponent } from './tabs/tabs.component';
import { FamilyGroupsComponent } from './components/family-groups/family-groups.component';
import { BeneficiariesComponent } from './components/beneficiaries/beneficiaries.component';
import { PetsComponent } from './components/pets/pets.component';
import { PipesModule } from '../pipes/pipes.module';
import { SearchInputComponent } from './search-input/search-input.component';

import { ScheduleComponent } from './schedule/schedule.component';
import { CustomCalendarModule } from '../custom-calendar/custom-calendar.module';
import { InputVozComponent } from './components/input-voz/input-voz.component';
import { ImagesViewerComponent } from './images-viewer/images-viewer.component';
import { ScheduleProgramatorComponent } from './schedule-programator/schedule-programator.component';
import { ScheduleSetterComponent } from './schedule-programator/schedule-setter/schedule-setter.component';
import { InfoShiftScheduleComponent } from './schedule-programator/info-shift-schedule/info-shift-schedule.component';
import { StepperComponent } from './schedule-programator/stepper/stepper.component';
import { FormSelectTextComponent } from './components/form-select-text/form-select-text.component';
import { BranchSelectTextComponent } from './components/branch-select-text/branch-select-text.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { FileImageInputComponent } from './components/file-image-input/file-image-input.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CompleteProfileComponent } from './components/complete-profile/complete-profile.component';
import { CompleteCompanyProfileComponent } from './components/complete-company-profile/complete-company-profile.component';
import { MapSearchComponent } from './map-search/map-search.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { ModalCompany } from './modal-company/modal-company.component';
import { CardSubservice } from './card-subservice/card-subservice.component';
import { AppointmentsSearchComponent } from './appointments-search/appointments-search.component';
import { AppointmentsUserComponent } from './appointments-search/appointments-user/appointments-user.component';
import { UserCitasViewComponent } from './appointments-search/user-citas-view/user-citas-view.component';
import { MapMarkersComponent } from './map-markers/map-markers.component';
import { ApproveCompanyComponent } from './approve-company/approve-company.component';
import { CustomCalendarExtendedModule } from '../custom-calendar-extended/custom-calendar-extended.module';
import { FamilyAdminComponent } from './components/family-groups/family-admin/family-admin.component';
import { BeneficiariesFamilyComponent } from './components/family-groups/beneficiaries-family/beneficiaries-family.component';
import { AppointmentViewComponent } from './appointment-view/appointment-view.component';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import { PermissionsManagerComponent } from './permissions-manager/permissions-manager.component';
import { TermsAndConditionsComponent } from '../utils/terms-and-conditions/terms-and-conditions.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { CustomMessageComponent } from './custom-message/custom-message.component';
import { PetCardComponent } from './components/pet-card/pet-card.component';import { MainServiceDetailsComponent } from './main-service-details/main-service-details.component';
import { ViewAppointmentComponent } from './view-appointment/view-appointment.component';
import { FamilyGroupAndBeneficiaryComponent } from './family-group-and-beneficiary/family-group-and-beneficiary.component';
import { FamilyGroupAndPetComponent } from './family-group-and-pet/family-group-and-pet.component';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';


import { PetTicketComponent } from './tickets/pet-ticket/pet-ticket.component';
import { PatientTicketComponent } from './tickets/patient-ticket/patient-ticket.component';
import { NotFoundDataComponent } from './not-found-data/not-found-data.component';
import { LoaderComponent } from './loader/loader.component';
import { TablesComponent } from './tables/tables.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TrinityCrudComponent } from './trinity-crud/trinity-crud.component';
import { AgreementTemplateComponent } from '../agreement-template/agreement-template.component';
import { LandingPageModule } from "../landing-page/landing-page.module";



const componentList = [
  AlbumsComponent,
  AppointmentsSearchComponent,
  AppointmentsUserComponent,
  AppointmentViewComponent,
  ApproveCompanyComponent,
  AutocompleteComponent,
  BeneficiariesComponent,
  BeneficiariesFamilyComponent,
  BranchSelectTextComponent,
  CardComponent,
  MainServiceDetailsComponent,
  CardSubservice,
  CellPhonesListComponent,
  ChangeImageComponent,
  CompleteCompanyProfileComponent,
  CompleteProfileComponent,
  ExperienceListComponent,
  FamilyAdminComponent,
  FamilyGroupsComponent,
  FileImageInputComponent,
  FormSelectTextComponent,
  ImageCropComponent,
  ImageLoaderComponent,
  ImagesViewerComponent,
  InfoShiftScheduleComponent,
  InputVozComponent,
  MapMarkersComponent,
  MapSearchComponent,
  ModalCompany,
  PaginatorCustomComponent,
  PasswordInputComponent,
  PermissionsManagerComponent,
  PetsComponent,
  PetCardComponent,
  ProgressBarComponent,
  RangeInputComponent,
  ScheduleComponent,
  ScheduleProgramatorComponent,
  ScheduleSetterComponent,
  SearchInputComponent,
  SpecialtyListComponent,
  StepperComponent,
  StudiesListComponent,
  TipoEmpresaComponent,
  UserCitasViewComponent,
  VerAyudasComponent,
  ViewAppointmentComponent,
  FamilyGroupAndBeneficiaryComponent,
  FamilyGroupAndPetComponent,
  PetTicketComponent,
  PatientTicketComponent,
  NotFoundDataComponent,
  LoaderComponent,
  TablesComponent,
  DashboardComponent,
  TrinityCrudComponent,
  VerifyCodeComponent,
  AgreementTemplateComponent
];

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [...componentList],
  imports: [
    CommonModule,
    CustomCalendarExtendedModule,
    CustomCalendarModule,
    CustomFormModule,
    DirectivesModule,
    FormsModule,
    ImageCropperModule,
    LottieModule.forRoot({ player: playerFactory }),
    PipesModule,
    ReactiveFormsModule,
    ReusableModalModule,
    TabsComponent,
    TermsAndConditionsComponent,
    ButtonsComponent,
    CustomMessageComponent,
    GoogleSigninButtonModule,
    LandingPageModule
],
  exports: [...componentList]
})
export class SharedComponentsModule { }
