import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { authReducer, authStateFeatureKey, AuthEffects } from './state';
import { DirectivesModule } from '../directives/directives.module';

// Google
import { GoogleSigninButtonModule  } from '@abacritt/angularx-social-login';
import { CustomFormModule } from '../custom-forms/custom-form.module';
import { PipesModule } from '../pipes/pipes.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthComponent } from './auth-component/auth-component';
import { ButtonsComponent } from '../shared-components/buttons/buttons.component';

@NgModule({
  declarations: [LoginComponent, RegisterComponent, AuthComponent, ForgotPasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormModule,
    DirectivesModule,
    StoreModule.forFeature(authStateFeatureKey, authReducer),
    EffectsModule.forFeature([AuthEffects]),
    GoogleSigninButtonModule,
    PipesModule,
    ButtonsComponent,
    SharedComponentsModule,
  ],
  exports: [],
})
export class AuthModule {}
