import { ApiRes, Experience, Studies, SpecialityDoctor, MemberUser } from '@interfaces/information.interface';
import { ApiService, SweetAlertService, ModalService } from '@services';
import { Observable, Subject, firstValueFrom, map } from 'rxjs';
import { UpdateOption, UpdateUserData } from '@interfaces/user.interface';
import { loginSuccess } from 'src/app/auth/state/auth.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Appointment, AppointmentTemp, SendTicketAppointment } from '@interfaces/appointments';
import { MergeAccounts } from '@interfaces/session.interface';
import { initSession } from 'src/app/pages/session/state/session.actions';
import { closeModal } from 'src/app/reusable-modal/state/modal.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private resetSource = new Subject<boolean>();
  resetObservable$ = this.resetSource.asObservable();
  

  constructor(
    private apiService: ApiService,
    private swalService: SweetAlertService,
    private modalService: ModalService,
    private store: Store) { }

  // Profile Doctor
  async updateProfileDoctor(body: any) {
    let response = await firstValueFrom(this.apiService.putRequest('doctor/profile', body));
    return response;
  }

  // specialities
  async createSpeciality(body: SpecialityDoctor) {
    let response = await firstValueFrom(this.apiService.postRequest('doctor/speciality', body));
    return response;
  }

  async deleteSpeciality(idSpeciality: string) {
    let response = await firstValueFrom(this.apiService.deleteRequest(`doctor/speciality/${idSpeciality}`));
    return response;
  }

  // Studies
  async createStudy(body: Studies) {
    let response = await firstValueFrom(this.apiService.postRequest('user/study', body));
    return response;
  }

  async updateStudy(body: Studies) {
    let response = await firstValueFrom(this.apiService.putRequest('user/study', body));
    return response;
  }

  async deleteStudy(idStudy: string) {
    let response = await firstValueFrom(this.apiService.deleteRequest(`user/study/${idStudy}`));
    return response;
  }

  // User
  async updateUserById(id:string,body: any) {
    let response = await firstValueFrom(this.apiService.putRequest(`user/byId/${id}`, body));
    return response;
  }

  async updateUser(body: MemberUser) {
    let response = await firstValueFrom(this.apiService.putRequest('user', body));
    return response;
  }

  //Experience
  async createExperience(body: Experience) {
    let response = await firstValueFrom(this.apiService.postRequest('user/experience', body));
    return response;
  }

  async updateExperience(body: Experience) {
    let response = await firstValueFrom(this.apiService.putRequest('user/experience', body));
    return response;
  }

  async deleteExperience(idExperience: string) {
    let response = await firstValueFrom(this.apiService.deleteRequest(`user/experience/${idExperience}`));
    return response;
  }

  async getUserByDocument(document: string) {
    let response = await firstValueFrom(this.apiService.getRequest(`user/${document}`));
    return response;
  }

  async getCheckEmail(email: string) {
    let response = await firstValueFrom(this.apiService.getRequest(`user/check-email/${email}`));
    return response;
  }

  updateUserData(userData: UpdateUserData) {

    return this.apiService.putRequest('user/complete-google-profile', userData).pipe(
      map((res: ApiRes) => {

        if (res.ok) {

          this.store.dispatch(loginSuccess({ body: res.body }));
          this.swalService.lauchSwal(
            '',
            res.message,
            'success'
          );

          this.modalService.closeModal();

        } else this.swalService.lauchSwal('', res.message, 'warning');

        return res;
      })
    );
  }

  getAppointments(type: string): Observable<any> {
    let endpoint: string;
    switch (type) {
      case 'user_active':
        endpoint = 'appointments/user/active';
        break;
      case 'beneficiaries_active':
        endpoint = 'appointments/beneficiaries/active';
        break;
      case 'pets_active':
        endpoint = 'appointments/pets/active';
        break;
      case 'user_history':
        endpoint = 'appointments/user/history';
        break;
      case 'beneficiaries_history':
        endpoint = 'appointments/beneficiaries/history';
        break;
      case 'pets_history':
        endpoint = 'appointments/pets/history';
        break;
      default:
        throw new Error('Tipo de cita no válido');
    }

    return this.apiService.getRequest(endpoint).pipe(
      map((response: any) => {
        return response.body;
      })
    );
  }

  // Peticion para editar las citas activas
  editAppointments(edit: any): Observable<any> {
    return this.apiService.putRequest(`appointments`, edit).pipe(
      map((response: any) => {
        return response
      })
    )
  }

  // Obtener info de grupos familiares y beneficiarios del user logeado

  getGroupsFamilyAndBeneficiries(): Observable<any> {
    return this.apiService.getRequest(`appointments/details`).pipe(
      map((response: any) => {
        return response.body
      })
    )
  }

  //Sacar cita
  async postAppointment(data: Appointment) {
    let response = await firstValueFrom(this.apiService.postRequest(`appointments`, data));
    return response;
  }

  //Sacar cita Temp
  async postAppointmentTemp(data: any) {
    let response = await firstValueFrom(this.apiService.postRequest(`appointments/temporal`, data));
    return response;
  }

  // Peticion para cancelar las citas
  cancelAppointments(edit: any) {
    return this.apiService.putRequest(`appointments/cancel`, edit).pipe(
      map((response: any) => {
        return response
      })
    )
  }

  getUserOptions() {
    return this.apiService.getRequest('user/options');
  }

  updateOption(data: UpdateOption) {
    return this.apiService.putRequest('user/options', data);
  }

  // Enviar ticket de cita medica
  async sendTicketAppointment(data: SendTicketAppointment){
    let response = await firstValueFrom(this.apiService.postRequest(`user/send-discount-order`,data ));
    return response;
  }

  // Resetea el input de municipalytis (FormAutocomplete) desde el componente padre (User-home)
  resetForm(){
    this.resetSource.next(true);
  }

  // Obtener las mascotas de los grupos familiares pasando el token
  getPetsGroupsFamily(): Observable<any> {
    return this.apiService.getRequest(`appointments/pets-details`).pipe(
      map((response: any) => {
        return response.body
      })
    )
  }

  mergeAccounts(data: MergeAccounts) {
    return this.apiService.postRequest('user/merge-account', data).pipe(
      map((apiRes: ApiRes) => {
        const { body, ok } = apiRes;

        if(ok) {
          this.swalService.lauchSwal('', 'Se ha recuperado la cuenta con éxito.', 'success');
          this.store.dispatch(initSession( {sessionData: body }));
          this.store.dispatch(closeModal());
        }
  
        return ok;
      })
    );
  }

}
