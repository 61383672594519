import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { EncryptService } from 'src/app/services/encrypt.service';
import { login } from '../state/auth.actions';
import { InformationService } from 'src/app/services/information.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';
import { GoogleService } from '../services/google.service';
import { GoogleUser } from 'src/app/interfaces/auth.interface';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastServiceService } from 'src/app/services/toast-service.service';

const production = environment.production;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  iconPath= production ? '../../../../../assets/images/logo_black_font.png':'../../../../../assets/images/logo_black_font-dev.png';

  private destroy$ = new Subject<void>();
  public metodo = '';
  public loginForm!: FormGroup;
  public codigoForm!: FormGroup;
  public passwordForm!: FormGroup;
  public submitted = false;
  public contrasenasNoValidas = false;

  constructor(
    private store: Store,
    private informationService: InformationService,
    private encryptService: EncryptService,
    private authService: AuthService,
    private swalService: SweetAlertService,
    private toastService: ToastServiceService,
    public router: Router,
    private googleService: GoogleService,
  ) { }

  ngOnInit(): void {
    console.log('LoginComponent iniciado');
    this.iniciarFormularioLogin()
    this.codigoForm = new FormGroup({});
    this.passwordForm = new FormGroup({});

    //  this.googleService.getUser().subscribe((user: GoogleUser) => this.loginByGoogle(user));
    this.googleService.getUser().pipe(
      takeUntil(this.destroy$)
    ).subscribe((user: GoogleUser) => this.loginByGoogle(user));
  }

  ngOnDestroy(): void {
    // console.log('LoginComponent destruido');
    this.destroy$.next();
    this.destroy$.complete();
  }

  login(form: FormGroup) {

    this.submitted = true;
    this.contrasenasNoValidas = false;

    if (this.loginForm.valid) {

      const user = {
        email: form.value.correo,
        password: this.encryptService.manageActions({ action: 'password', data: form.value.contrasena })
      };

      this.informationService.getPublicKey().then((key) => {

        const encryptData = this.encryptService.manageActions({ action: 'publicKey', data: JSON.stringify(user), key });
        this.store.dispatch(login({ user: encryptData }));
      });

    } else this.swalService.showError('Error !', 'Usuario o contraseña incorrecta.');

  }

  loginByGoogle(user: GoogleUser) {

    if (!user) return;

    const dataLogin = {
      email: user.email,
      method: 'Google'
    };

    this.informationService.getPublicKey().then((key) => {

      const encryptData = this.encryptService.manageActions({ action: 'publicKey', data: JSON.stringify(dataLogin), key });
      this.store.dispatch(login({ user: encryptData }));
    });
  }

  changeMetodo(metodo: string) {
    this.submitted = false;
    this.metodo = metodo;
    this.iniciarFormularioCodigo(metodo);
  }

  iniciarFormularioLogin() {
    this.loginForm = new FormGroup({
      correo: new FormControl<string>('', [Validators.required, Validators.email]),
      contrasena: new FormControl<string>('', Validators.required),
    });
  }

  iniciarFormularioCodigo(metodo: string) {
    if (metodo == 'correo') {
      this.codigoForm = new FormGroup({
        caso: new FormControl<string>(this.metodo, Validators.required),
        email: new FormControl<string>('', [
          Validators.required,
          Validators.email,
        ]),
      });
    } else {
      this.codigoForm = new FormGroup({
        caso: new FormControl<string>(this.metodo, Validators.required),
        numeroDocumento: new FormControl<string>('', Validators.required),
        telefono: new FormControl<string>('', Validators.required),
      });
    }
  }

  iniciarFormularioPassword(_id: string) {
    this.passwordForm = new FormGroup({
      _id: new FormControl<string>(_id, Validators.required),
      codigo: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
      contrasena: new FormControl<string>('', Validators.required),
      contrasena2: new FormControl<string>('', Validators.required),
    });
  }

  validarContrasenasIgualesPassword() {
    this.validarContrasenasIgualesForm(this.passwordForm);
  }

  private validarContrasenasIgualesForm(form: FormGroup) {
    const contrasena = form.get('contrasena')?.value;
    const contrasena2 = form.get('contrasena2')?.value;

    if (contrasena !== contrasena2) {
      form.get('contrasena2')?.setErrors({ contrasenasNoCoinciden: true });
      this.contrasenasNoValidas = true;
    } else {
      form.get('contrasena2')?.setErrors(null);
    }
  }

  isCampoInvalidoLogin(campo: string) {
    const control = this.loginForm.controls[campo];
    return control.errors && (control.touched || this.submitted);
  }

  isCampoInvalidoCodigo(campo: string) {
    const control = this.codigoForm.controls[campo];
    return control.errors && (control.touched || this.submitted);
  }

  isCampoInvalidoPassword(campo: string) {
    const control = this.passwordForm.controls[campo];
    return control.errors && (control.touched || this.submitted);
  }

  generateVerificationCode(form: FormGroup) {
    this.submitted = true;
    if (this.codigoForm.valid) {
      this.authService
        .generateVerificationCode(form.value)
        .subscribe((resp) => {
          if (resp.ok) {
            this.submitted = false;
            this.iniciarFormularioPassword(resp.body);
          }
        });
    } else {
      this.swalService.showError('Alerta !', 'Código invalido.');
    }
  }

  changePassword(form: FormGroup) {
    this.submitted = true;
    this.contrasenasNoValidas = false;
    this.validarContrasenasIgualesPassword();

    if (this.passwordForm.valid) {
      delete form.value.contrasena2
      form.value.contrasena = this.encryptService.manageActions({ action: 'password', data: form.value.contrasena });;
      this.authService.changePassword(form.value).subscribe((resp) => {
        if (resp.ok) {
          this.submitted = false;
        }
      })

    } else {
      this.swalService.showError('', 'Las contraseñas no coinciden.');
    }
  }

  inputPasswordLogin(fieldName: string, value: string) {
    this.loginForm.controls[fieldName].setValue(value);
  }

  inputPasswordChange(fieldName: string, value: string) {
    this.passwordForm.controls[fieldName].setValue(value);
  }

}
