import { loginData } from 'src/app/interfaces/auth.interface';
import { Component, Input, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ApiService, IntrojsService } from '@services';
import { Observable, take, zip } from 'rxjs';
import { AuthState } from 'src/app/auth/state';
import { userData } from 'src/app/auth/state/auth.selectors';
import { CalendarsComponent } from 'src/app/provider-shared/components/calendars/calendars.component';

import { environment } from 'src/environments/environment';
import { SessionState } from 'src/app/pages/session/state/session.reducers';
import { getRolSelect } from 'src/app/pages/session/state/session.selectors';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['../../custom-forms/form-styles.scss', './schedule.component.scss']
})
export class ScheduleComponent {
  @Input() simpleDoctor: boolean = false;

  @ViewChild(CalendarsComponent, { static: false }) private calendar!: CalendarsComponent
  userData$: Observable<any>

  filterServiceList: any[] = []
  filterDoctorList: any[] = []
  filterTypeList: any[] = []

  companyData!: any

  branchSelected!: number | string
  municipalyId: number | string
  serviceId!: number | string
  doctorId!: number | string
  // branchId: number | string

  ready: boolean = false

  scheduleList!: any[]

  form!: FormGroup

  /** url root to get data from api  */
  // private urlApi = 'https://www.prevenirexpress.com:7000'
  private apiUrl = environment.apiUrl;

  /**
  * Route to obtain the services that are active to make appointments provided by a branch.
  * "/serviciossucu/idMunicipio/idSucursal"
   */
  private endPointServices = '/serviciossucu/13301/4'

  /**
  * Route to obtain active branches providing a service.
  * "medicosactivos/*idServicio/*idMunicipio/*idSucursal"
   */
  private endPointDoctors = '/medicosactivos/69/13301/4'



  constructor(
    private formBuilder: FormBuilder,
    private apiServices: ApiService,
    private store: Store<AuthState>,
    private introService: IntrojsService,
    private sessionStore: Store<SessionState>
  ) {
    this.filterTypeList = [{ id: 'all', label: 'Todas las citas' }, { id: 'actives', label: 'Citas activas' }, { id: 'history', label: 'Historial de citas' }]
    this.userData$ = this.store.pipe(select(userData));
    this.municipalyId = 13301

    this.form = formBuilder.group({
      filterService: [null, [Validators.required]],
      filterDoctor: [null, []],
      filterType: [null, []]
    })
  }

  ngAfterViewInit() {
    let rollSession$ = this.sessionStore.pipe(select(getRolSelect))
    
    rollSession$.pipe(take(1)).subscribe((companyData) => this.companyData = companyData)

    this.form.controls['filterService'].valueChanges.subscribe((_service: string | number) => {
      if (_service) {
        this.serviceId = _service
        this.getDoctors(_service, this.branchSelected)
      }
    })

    this.form.controls['filterDoctor'].valueChanges.subscribe((_doctor: string | number) => {
      if (_doctor) {
        this.doctorId = _doctor
        this.form.controls['filterType'].setValue('all')
      }
    })

    this.form.controls['filterType'].valueChanges.subscribe((_typeSchedule: string | number) => {
      if (_typeSchedule) {
        this.getSchedules(_typeSchedule, this.branchSelected, this.serviceId, this.doctorId)
      }
    })

    setTimeout(() => {
      this.form.controls['filterService'].setValue('0')
    }, 500)

    // setTimeout(() => {
    //   this.introService.launchTour([
    //     {
    //       element: '#branchSelector',
    //       title: 'Selección de sucursal',
    //       intro: 'Selecciona e intercambia entre las sucursales de tu empresa para consultar las citas... ',
    //       position: 'bottom',
    //     },
    //     {
    //       element: '#filtersSchedule',
    //       title: 'Filtrar tus citas',
    //       intro: 'En esta secciòn podras filtrar tus citas...',
    //       position: 'bottom',
    //     },
    //     {
    //       element: '#filterService',
    //       title: 'Filtrar por servicio',
    //       intro: 'Selecciona entre los diferentes servicios...',
    //       position: 'bottom',
    //     },
    //     {
    //       element: '#filterDoctor',
    //       title: 'Doctor o especialista',
    //       intro: 'Seguido selecciona un doctor en particular o todos los doctores registrados...',
    //       position: 'top',
    //     },
    //     {
    //       element: '#filterType',
    //       title: 'Filtrar por tipo',
    //       intro: 'Selecciona entre todas las citas, las citas activas o el historial de las citas...',
    //       position: 'right',
    //     },
    //     {
    //       element: '#schedulesCalendar',
    //       title: 'Calendario de citas',
    //       intro: 'En este calendario podras visualizar las citas...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '.month',
    //       title: 'Cambia entre meses',
    //       intro: 'Puedes navegar entre los meses...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '.day.previous',
    //       title: 'Los dias previos',
    //       intro: 'Los dias previos a la fecha se identificaran de esta forma...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '.day.today',
    //       title: 'La fecha actual',
    //       intro: 'La fecha actual se identificaran de esta forma...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '.day.holiday',
    //       title: 'Los dias festivos',
    //       intro: 'Los dias festivos se identificaran de esta forma...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '.calendar__legend',
    //       title: 'Leyenda de tipo de citas',
    //       intro: 'De esta forma visualizaras el tipo de citas en el calendario, sea activas o canceladas...',
    //       position: 'bottom'
    //     },
    //     {
    //       element: '#schedulesCalendar',
    //       title: 'Finalmente...',
    //       intro: '¡Eso es todo! Comienza a usar el calendario de citas',
    //       position: 'bottom'
    //     }
    //   ]);
    // }, 3000);
  }

  changeBranch(event: any) {
    let { branchId } = event
    this.branchSelected = branchId
    this.getServices(this.branchSelected)
    this.restartControls()
  }

  /**
   * @param branch
   */
  private async getServices(branch: string | number) {
    this.apiServices.getRequest(`company/active-subservices/${branch}`, this.apiUrl).subscribe(({ body }) => {
      let response: any[] = body.map((_service: any) => _service["subService"])
      
      
      if (!response.length) {
        this.filterServiceList = []
        this.form.controls['filterService'].disable()
        return
      }
      
      this.form.controls['filterService'].enable()
      this.form.controls['filterService'].setValue('0')
      this.filterServiceList = [{ subServiceId: 0, subserviceName: 'TODOS' }, ...response]
    })
  }

  private getDoctors(service: string | number, branch: string | number) {
    this.apiServices.getRequest(`company/active-doctors/${branch}/${service}/`, this.apiUrl).subscribe(({ body: { doctors } }) => {
      this.filterDoctorList = [{ doctorId: 0, fullName: 'TODOS' }, ...doctors]
      this.form.controls['filterDoctor'].setValue('0')
    })
  }

  /**
   * Method to get by api the schedules
   * @param type Type schedules to get: 'all'|'actives'|'history'
   */
  private getSchedules(type: string | number, idBranch: string | number, idService: string | number, idDoctor: string | number) {
    try {
      let endpoint = "appointments/appointments-branch"
      let reqData = {
        "appointmentsType": type,
        "branchId": idBranch,
        "doctorId": idDoctor,
        "subServiceId": idService,
      }

      this.apiServices.postRequest(endpoint, reqData).subscribe(({ body: _schedules }) => {
        this.scheduleList = []
        this.scheduleList.push(..._schedules)
      })

    } catch (error) {
      console.debug('error obtening data schedules')
    }
  }

  private restartControls() {
    this.scheduleList = []

    this.form.controls['filterService'].setValue(null)
    this.form.controls['filterService'].markAsUntouched()
    this.form.controls['filterService'].setErrors([])

    this.form.controls['filterDoctor'].setValue(null)
    this.form.controls['filterDoctor'].markAsUntouched()
    this.form.controls['filterDoctor'].setErrors([])

    this.form.controls['filterType'].setValue(null)
    this.form.controls['filterType'].markAsUntouched()
    this.form.controls['filterType'].setErrors([])

  }
}
