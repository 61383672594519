import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TempService {

    private timerValue: number = 0;
    private intervalId: any;

    public onTimerTick = new Subject<string>(); // Emitir temporizador en tiempo real (a estos toca suscribirse desde el componente y almacenarlo en una variable para usarlo en el template)
    public onTimerEnd = new Subject<void>(); // Para avisar cuando llegue a cero

    constructor() {



    }

    // Iniciar el temporizador
    startTimer(minutes: number): void {
        // Convertimos los minutos en segundos
        this.timerValue = minutes * 60;

        // Si ya hay un temporizador corriendo, lo detenemos
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        // Iniciamos el intervalo de 1 segundo
        this.intervalId = setInterval(() => {
            this.timerValue--;

            // Emitimos el tiempo 
            this.onTimerTick.next(this.formatTime(this.timerValue));

            //si el temporizador llega a cero :3
            if (this.timerValue <= 0) {
                clearInterval(this.intervalId);
                this.onTimerEnd.next(); // Emitimos el evento cuando llega a cero
            }
        }, 1000);
    }

    // Reiniciar el temporizador desde afuera
    resetTimer(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.timerValue = 0;
        this.onTimerTick.next(this.formatTime(0)); // Emitimos 0:00 cuando se reinicia
        // console.log('Temporizador reiniciado');
    }

    // Formatear el tiempo en mm:ss
    private formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }

}