
<div class="wrapper-body">


  <div class="custom-card">
  <div class="wrapper">
    <div class="cuerpo">
      <div class="noise"></div>
      <div class="overlay"></div>
      <div class="terminal">
        <div class="card">
          <section class="hero">
            <div class="jumbotron jumbotron-fluid mb-0">
              <div class="container">
                <div class="row justify-content-center text-center">
                  <div class="col-md-10 col-lg-6">
                    <div style="display: flex; justify-content: center; align-items: center;">
                      <img src="../assets/images/trinity.png" alt="Email" style="max-width: 100%; height: auto;">
                    </div>
                    <br>
                    <p class="lead">En Trinity capacitamos a tu equipo para enfrentar los riesgos de seguridad informática, transformando amenazas en conocimientos y protección.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>



    <section id="sec-pricing" class="sec-pricing pt-5">
      <div class="container">

        <div style="display: flex; justify-content: center; align-items: center;">
          <iframe width="666" height="415" src="//www.youtube.com/embed/Cbti19KM3wk" style="border-radius: 15px;" frameborder="0" allowfullscreen></iframe>
        </div><br><br><br>
        <h1 class="h4 text-center">Nuestra tematica</h1><br>

        <div class="row justify-content-center">

          <div class="col-md-3">
            <div class="card custom-card text-center">
              <div class="tematic">
                <span class="icon-size card-text fa fa-briefcase"></span>
                <h4 class="card-title h5">Empresas </h4>
                <p class="card-text justify-text">Identifica y mitiga las vulnerabilidades que pueden comprometer la seguridad de tu empresa. Aprende a fortalecer tus sistemas y proteger tus datos más valiosos.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card custom-card text-center">
              <div class="tematic">
                <span class="icon-size card-text fa fa-users"></span>
                <h4 class="card-title h5">Personas </h4>
                <p class="card-text justify-text">Descubre cómo proteger tu información personal y evitar ser víctima de ataques cibernéticos. Aprende a navegar en el mundo digital con seguridad y confianza.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card custom-card text-center">
              <div class="tematic">
                <span class="icon-size card-text fa fa-television"></span>
                <h4 class="card-title h5">Internet  </h4>
                <p class="card-text justify-text">Explora los riesgos que existen en el uso diario de Internet y cómo puedes defenderte de amenazas comunes como el phishing, malware y robo de identidad.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card custom-card text-center">
              <div class="tematic justify-content-center">
                <span class="icon-size card-text fa fa-shield"></span>
                <h4 class="card-title h5">Marco legal  </h4>
                <p class="card-text justify-text">Conoce las normativas en ciberseguridad. Asegúrate de que tu empresa cumpla con las leyes para evitar sanciones y mantener la confianza de tus clientes.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section id="sec-contact" class="sec-contact pt-5 ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-7">
            <h1 class="h4 text-center">¿Quieres aprender más? Accede a nuestro canal</h1><br>

            <form action="" method="">
              <fieldset class="form-group">
                <label class="card-title" for="formName">Nombre:</label>
                <input id="formName" class="form-control" type="text" placeholder="Nombre" required>
              </fieldset>

              <fieldset class="form-group">
                <label class="card-title" for="formEmail1">Correo:</label>
                <input id="formEmail1" class="form-control" type="email" placeholder="Correo" required>
              </fieldset>

              <fieldset class="form-group">
                <label class="card-title" for="formName">Celular:</label>
                <input id="formName" class="form-control" type="text" placeholder="Celular" required>
              </fieldset>

              <fieldset class="form-group">
                <label class="card-title" for="formName">Ciudad:</label>
                <input id="formName" class="form-control" type="text" placeholder="Cuidad" required>
              </fieldset>

              <fieldset class="form-group text-center">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="acceptTerms" required>
                  <label class="form-check-label h4" for="acceptTerms">
                    Acepto los <a class="card-title"  href="#">términos y condiciones</a>
                  </label>
                </div>
                <button class="btn btn-primary" type="submit" disabled id="submitBtn">¡Regístrate gratis!</button>
              </fieldset>

            </form>
          </div>
        </div>
      </div>
    </section>
    <section id="sec-testimonials" class="sec-testimonials">
      <div class="container">
        <h1 class="h4 mb-5 text-center">Testimonios de nuestros asistentes</h1>

        <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
            <li data-target="#carouselIndicators" data-slide-to="3"></li>

          </ol>

          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <blockquote class="blockquote">
                    <small class="card-text">Las capacitaciones de Trinity nos han permitido identificar vulnerabilidades en nuestro sistema antes de que se conviertan en problemas graves.</small>

                    <footer class="blockquote-footer card-text mt-2">Timothy Wilson, CTO</footer>
                  </blockquote>
                </div>
              </div>
            </div>


          <div class="carousel-item">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <blockquote class="blockquote">
                  <small class="card-text">He asistido a varias capacitaciones con Trinity, y siempre salgo con herramientas clave para proteger a mi empresa de los riesgos cibernéticos. Altamente recomendable.</small>

                  <footer class="blockquote-footer card-text mt-2">Carlos Martínez, Gerente de TI</footer>
                </blockquote>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <blockquote class="blockquote">
                  <small class="card-text">Las sesiones de capacitación de Trinity no solo me enseñaron sobre riesgos informáticos, sino también cómo prevenirlos con prácticas eficaces. Excelente equipo y contenido.</small>

                  <footer class="blockquote-footer card-text mt-2">Laura Gómez, Directora de Seguridad</footer>
                </blockquote>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <blockquote class="blockquote">
                  <small class="card-text">Las capacitaciones de Trinity me dieron una nueva perspectiva sobre la ciberseguridad. Ahora mi equipo está más preparado para enfrentar amenazas. ¡Gracias, Trinity!</small>

                  <footer class="blockquote-footer card-text mt-2">Javier Pérez, Responsable de Sistemas</footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

          <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="fa fa-angle-left fa-2x"></span>
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>

          <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="fa fa-angle-right fa-2x"></span>
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>

    <footer class="footer-class">
      <div class="container">
        <ul class="list-inline mb-0 text-center">
          <li class="list-inline-item">
            <a href=""><span class=" card-text fa fa-twitter"></span></a>
          </li>

          <li class="list-inline-item ">
            <a href=""><span class=" card-text fa fa-google-plus"></span></a>
          </li>

          <li class="list-inline-item" >
            <a href=""><span class=" card-text fa fa-instagram"></span></a>
          </li>

          <li class="list-inline-item ">
            <a href=""><span class=" card-text fa fa-envelope-o"></span></a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</div>


</div>


