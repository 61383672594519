import { Injectable } from '@angular/core';
import { ApiRes } from '@interfaces/information.interface';
import { StatesNgrxService, SweetAlertService } from '@services';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Servicio para la gestión de eventos a través de sockets.
 *
 * @class
 * @@Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  private swalOPtions = {
    text: '',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: 'var(--primary)',
    title: '',
    icon: 'success',
  };

  private subscription: Subscription = new Subscription();

  /**
   * Constructor del servicio SocketsService.
   *
   * @constructor
   * @param {Socket} socket - Instancia de Socket proporcionada por ngx-socket-io.
   */
  constructor(
    private socket: Socket,
    private swalService: SweetAlertService,
    private statesNgrxService: StatesNgrxService
  ) {}

  /**
   * Emite un evento a través del socket.
   *
   * @param {string} evento - Nombre del evento a emitir.
   * @param {any} info - Información a enviar con el evento.
   */
  emit(evento: string, info: any) {
    this.socket.emit(evento, info);
  }

  /**
   * Obtiene un Observable para escuchar un evento específico.
   *
   * @param {string} evento - Nombre del evento a escuchar.
   * @returns {Observable<any>} - Observable que emite datos cuando ocurre el evento.
   */
  getEvent(evento: any) {
    return this.socket.fromEvent(evento);
  }

  /**
   * Escucha eventos de tipo 'error-message' y emite un Observable con los mensajes de error.
   *
   * @returns {Observable<ApiRes>} - Observable que emite mensajes de error recibidos del servidor.
   */
  listenForErrors(): Observable<any> {
    return new Observable<ApiRes>((observer) => {
      this.socket.on('error-message', (errorMessage: any) => {
        observer.next(errorMessage);
      });
    });
  }

  manageSocketAppointments(
    action: 'finalize' | 'cancel' | 'confirm',
    appointmentId: string
  ) {
    if (action === 'cancel') {
      const idSubscription = this.statesNgrxService
        .getDataGlobalUser()
        .subscribe((resp: any) => {
          const whoCancelled = resp._id;
          this.cancelAppointment(appointmentId, whoCancelled);
          this.subscription.add(idSubscription);
        });
    } else if (action === 'confirm') {
      this.confirmAppointment(appointmentId);
    } else {
      this.finalizeAppointment(appointmentId);
    }
  }

  manageSocketTemporalAppointments(
    action: 'finalize' | 'cancel' | 'confirm',
    appointmentId: string,
  ): void {
    if (action === 'cancel') {
      const idSubscription = this.statesNgrxService
        .getDataGlobalUser()
        .subscribe((resp: any) => {
          const whoCancelled = resp._id;
          this.cancelTempAppointment(appointmentId, whoCancelled);
        });
        this.subscription.add(idSubscription);

    }
    else if(action === 'confirm') {
      this.completedTempAppointment(appointmentId);
    }
    // else {
    //   this.finalizeAppointment(appointmentId);
    // }
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }

  private async confirmAppointment(appointmentId: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Estás seguro?',
      icon: 'warning',
      text: '¡El paciente entrara al consultorio!',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result) this.emit('underAttentionAppointment', { appointmentId });
  }

  private async finalizeAppointment(appointmentId: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Cita éxitosa',
      icon: 'success',
      text: '¡Se va a finalizar la cita!',
      confirmButtonText: 'Completar',
      cancelButtonText: 'Cancelar',
    };

    const buttonClass = {
      confirmButtonClass: 'swal2-complete',
      cancelButtonClass: 'swal2-cancel',
    };

    const result = await this.swalService.launchConfirmSwal(this.swalOPtions, buttonClass);

    if (result) this.emit('finishAppointment', { appointmentId });
  }

  private async cancelAppointment(appointmentId: string, whoCancelled: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Estás seguro?',
      icon: 'warning',
      text: '¡Se va a Eliminar el ingreso!',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    };

    const buttonClass = {
      confirmButtonClass: 'swal2-confirm-delete',
      cancelButtonClass: 'swal2-cancel',
    };

    const result = await this.swalService.launchConfirmSwal(this.swalOPtions, buttonClass);

    if (result) this.emit('cancelAppointment', { appointmentId, whoCancelled });
  }

  async completedTempAppointment(temporalAppointmentId: string) {
    this.socket.emit('completedTemporalAppointment', {
      temporalAppointmentId: temporalAppointmentId,
    });
  }

  /**
 * Método privado para emitir el evento de cancelación de cita temporal.
 *
 * Muestra un modal de SweetAlert para que el usuario elija una razón de cancelación
 * antes de emitir el evento correspondiente.
 *
 * @param {string} temporalAppointmentId - El ID de la cita temporal.
 * @param {string} authorizedId - El ID de la persona que cancela.
 */
private async cancelTempAppointment(
  temporalAppointmentId: string,
  authorizedId: string
) {
  // Opciones de motivos de cancelación
  const options = [
    {
      value: 'Usuario no responde o no aparece',
      text: 'Usuario no responde o no aparece',
    },
    {
      value: 'Problema de salud resuelto',
      text: 'Problema de salud resuelto',
    },
    { value: 'Conflicto de horario', text: 'Conflicto de horario' },
    { value: 'Motivo personal', text: 'Motivo personal' },
  ];

  const buttonClass = {
    confirmButtonClass: 'swal2-confirm-delete',
    cancelButtonClass: 'swal2-cancel',
  };

  // Mostrar el modal de SweetAlert con las opciones de cancelación
  await this.swalService.showOptionsModal(
    '¿Estás seguro?', // Título del modal
    'Se va a cancelar la cita', // Texto del modal
    'Cancelar cita', // Texto del botón de confirmar
    'Volver', // Texto del botón de cancelar
    async (selectedOption: string) => {
      // Emitir el evento al socket solo si el usuario confirma
      this.socket.emit('cancelledTemporalAppointment', {
        temporalAppointmentId: temporalAppointmentId,
        whoCancelled: authorizedId,
        reason: selectedOption, // Pasar la razón seleccionada al evento
      });
    },
    options, // Opciones de cancelación
    buttonClass // Clases de botones
  );
}


  /**
   * Conecta el socket si está desconectado.
   */
  connect() {
    this.socket.connect();
  }

  /**
   * Desconecta el socket si está conectado.
   */
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
