<div *ngIf="familyGroup" class="config_title mb-2">Mascotas del Grupo, {{familyGroup.name }}</div>

<div style="padding: 10px;">
  <div class="tab-content" id="pills-tabContent">

    <div class="animated fadeIn">
      <ng-container [ngTemplateOutlet]="currentView"></ng-container>
    </div>

  </div>
</div>

<ng-template #petsList>

  <div class="row">
    <div class="col-12">
      <div class="config_container">
        <div *ngIf="pets.length>0">
          <div *ngIf="options" class="row fw_400">
            <div class="col-xl-6 col-md-6 col-xs-12 fw_500 align_start">
              <span class="config_campos_number">Has registrado {{pets.length}} mascota<span
                  *ngIf="pets.length>1">s</span></span>
            </div>
            <custom-buttons *ngIf="pets.length <= 4" class="col-xl-6 col-md-6 col-xs-12 align_end" text="Agregar mascota"
              title="Permite agregar mascota" textColor="#fff" type="button" icon="fa fa-plus" width="250px"
              (click)="iniciarPetAgregateForm()" />
          </div>
        </div>
        <div *ngIf="pets.length==0">
          <div *ngIf="options" class="row fw_400">
            <div class="col-xl-6 col-md-6 col-xs-12 fw_500 align_start">
              <span class="config_campos_number">Aun no has registrado a tus mascotas.</span>
            </div>
            <custom-buttons class="col-xl-6 col-md-6 col-xs-12 align_end" text="Agregar mascota" title="Permite agregar mascota"
              textColor="#fff" type="button" icon="fa fa-plus" width="250px" (click)="iniciarPetAgregateForm()" />
          </div>
        </div>
        <div *ngIf="!options" class="config_subtitle">Mascotas de este grupo familiar </div>

      </div>
    </div>
  </div>

  <!-- card -->
<div class="container-cards-desktop animated fadeIn">
  <app-card-subservice *ngFor="let pet of pets" [infoPet]="pet" caso="familyGroupPet" [viewOptions]="true"
    (editPetAction)="selectFamilyPet($event)" (deletePetAction)="deletePetId($event)">
  </app-card-subservice>
</div>

  <!-- card -->

  <div *ngIf="pets.length==0" class="table-responsive no-wrap mt-4">

    <table class="table vm no-th-brd pro-of-month">
      <thead>
        <tr>
          <th class="no_registrados">No hay mascotas registrados</th>
        </tr>
      </thead>
    </table>
  </div>

</ng-template>

<ng-template #petsAgregate>

  <div class="animated fadeIn">

    <div class="update_settings_header mb-4">
      <button type="button" class="btn btn-circle btn_back" title="Regresar" (click)="regresarPets()"><i
          class="ti ti-arrow-left"></i> </button>

      <!-- <custom-buttons type="button" class="btn btn-circle btn_back" (click)="regresarPets()" text="" textColor="#fff"
        [title]="'Regresar'" icon="ti ti-arrow-left">
        <i class="ti ti-arrow-left"></i>
      </custom-buttons> -->
      <div *ngIf="petSelected" class="settings_header_title">Actualizar Mascota</div>
      <div *ngIf="!petSelected" class="settings_header_title">Agregar Mascota al Grupo Familiar</div>
    </div>

    <form id="idFormPet" [formGroup]="petForm">

      <div class="row">

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputPetName'" helper="" label="Nombre de la Mascota" placeholder="ejm: Toby"
              [controlName]="'petName'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputSpecies'" helper="" label="Especie de la Mascota"
              placeholder="ejm: Perro, Gato, Pez, etc..." [controlName]="'species'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputBreed'" helper="" label="Raza de la Mascota"
              placeholder="ejm: Bulldog, Persa, etc..." [controlName]="'breed'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-select [id]="'idInputGender'" helper="Selecciona un genero de la lista" label="Género"
              placeholder="Seleccione..." [dataList]="genders" [controlName]="'gender'" [idField]="'value'"
              [valueField]="'name'">
            </form-select>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputColor'" helper="" label="Color de la Mascota"
              placeholder="ejm: Blanco con manchas grises, etc..." [controlName]="'color'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-select [id]="'idInputSterilized'" helper="Selecciona un estado de la lista"
              label="Fue Esterilizado/a?" placeholder="Seleccione..." [dataList]="sterilizationStates"
              [controlName]="'sterilized'" [idField]="'value'" [valueField]="'name'">
            </form-select>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-date [id]="'idInputBirth_date'" label="Fecha de Nacimiento (Aprox.)" [maxNow]="true"
              [controlName]="'birth_date'">
            </form-date>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputAge'" helper="" [type]="'number'" [required]="false"
              label="Edad Actual (Aproximada)" placeholder="Edad en años" [controlName]="'age'">
            </form-input>
          </div>
        </div>

        <div class="col-12 justify_content">
          <h4 class="fw_500">Selecciona una Imagen para tu Mascota</h4>
        </div>

        <div class="col-12 justify_content mb-4">
          <div class="box_shadow br_4" style="max-width: fit-content;min-width: 320px;">
            <div id="idInputFile" *ngIf="vista=='selectImage'" class="computer_container animated fadeIn fast">
              <div id="idDrop" class="image_drop " (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                (dragleave)="onDragleave($event)">
                <!-- <img class="rounded-image" src="../../../assets/images/icon/photo_silhouette.png" style="pointer-events: none;"> -->
                <img *ngIf="!imageDataUrl" class="rounded-image" style="pointer-events: none;"
                  src="../../../assets/images/icon/photo_silhouette.png" style="pointer-events: none;">
                <img *ngIf="imageDataUrl" class="prev_image" style="pointer-events: none;" [src]="imageDataUrl|getImage"
                  alt="">
                <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_title">
                  Arrastra una foto aquí
                </div>
                <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_subTitle">
                  - O -
                </div>
                <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
                  <button class="btn_computador" (click)="selectImage()">
                    <i class="mdi mdi-laptop pr_10"></i>Cargar Imagen
                  </button>

                  <!-- <custom-buttons class="btn_computador" (click)="selectImage()" text="" textColor="#fff" [title]="''"
                    icon="mdi mdi-laptop">
                    <i class="mdi mdi-laptop pr_10"></i>Cargar Imagen
                  </custom-buttons> -->
                  <input type="file" accept="image/*" (change)="onFileSelected($event)" hidden>
                </div>
                <div *ngIf="imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
                  <span class="notification_image">Suelta la foto para subirla</span>
                </div>
                <div *ngIf="errorTipo && !imageDrop && !cargandoFile" class="card_computador_btn">
                  <span class="notification_image">El tipo de archivo no es valido, tipos validos (jpg, jpeg, png)
                    <button class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false"> Ignorar</button>

                    <!-- <custom-buttons class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false"
                      text="Ignorar" textColor="#fff" [title]="''">
                    </custom-buttons> -->
                  </span>
                </div>
                <div *ngIf="cargandoFile" class="card_computador_btn">
                  <span class="notification_image">Subiendo foto...</span>
                </div>
              </div>
            </div>

            <div *ngIf="vista=='cropImage'" class="container_image animated zoomIn fast">
              <div class="card_image bg_2 width_2">
                <div class="card_imagen_header">
                  <button type="button" class="btn btn-circle btn_back_2 " (click)="regresar('selectImage')"
                    title="Regresar"><i class="ti ti-arrow-left"></i> </button>

                  <!-- <custom-buttons type="button" class="btn btn-circle btn_back_2 " (click)="regresar('selectImage')"
                    text="" textColor="#fff" [title]="'Regresar'" icon="ti ti-arrow-left">
                    <i class="ti ti-arrow-left"></i>
                  </custom-buttons> -->
                  <div class="title_header">Recortar Imagen</div>
                  <button type="button" class="btn btn-circle btn_back_2 " (click)="regresarYLimpiar('selectImage')"
                    title="Cancelar"><i class="ti ti-close"></i> </button>

                  <!-- <custom-buttons type="button" class="btn btn-circle btn_back_2 "
                    (click)="regresarYLimpiar('selectImage')" text="" textColor="#fff" [title]="'Cancelar'"
                    icon="ti ti-close">
                    <i class="ti ti-close"></i>
                  </custom-buttons> -->
                </div>
                <ng-container *ngIf="fileComponent">
                  <app-image-crop [fileComponent]="fileComponent" [type]="'Cover'" [postFile]="false" [role]="'user'"
                    [aspectInput]="4 / 3" [minHeight]="false" (cropperImageEmmiter)="cropperImageEmmiter($event)">
                  </app-image-crop>
                </ng-container>

              </div>
            </div>

          </div>
        </div>



      </div>




      <div class="settings_btn" style="margin-top: 40px;">

        <custom-buttons *ngIf="!petSelected" text="Agregar mascota" title="Agregar Mascota"
          textColor="#fff" icon="ti-plus ml-2" type="button" [rounded]="true" (click)="agregatePetGroup(petForm)" />

        <custom-buttons *ngIf="petSelected" text="Actualizar mascota" title="Actualizar Mascota"
          textColor="#fff" icon="ti-reload ml-2" type="button" [inputBackgroundColor]="'secondary'" [rounded]="true"
          (click)="updatePetGroup(petForm)" />

      </div>
    </form>
  </div>

</ng-template>
