import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePhone'
})
export class HidePhonePipe implements PipeTransform {

    transform(value: string, hideLength: number = 8, visibleEndLength: number = 2): string {
        if (!value) return '';
      
        const totalLength = value.length;
      
        // Si el numero  es menor o igual a 1, retorna el valor completo
        if (totalLength <= 1) {
          return value;
        }
      
        const firstChar = value.charAt(0);
        
        // Calcula cuántos numeros se pueden ocultar
        const maxHideLength = totalLength - 1 - visibleEndLength;
        const actualHideLength = Math.min(hideLength, maxHideLength > 0 ? maxHideLength : 0);
        
        const maskedSection = actualHideLength > 0 ? '*'.repeat(actualHideLength) : '';
        const startIndex = 1 + actualHideLength;
        const visibleEndSection = visibleEndLength > 0 ? value.slice(-visibleEndLength) : '';
        
        return firstChar + maskedSection + visibleEndSection;
      }

}
