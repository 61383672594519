import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuWeb, Role } from 'src/app/interfaces/auth.interface';
import { Store } from '@ngrx/store';
import { getMenu, getRoles, getRolSelect, rolSession } from 'src/app/pages/session/state/session.selectors';
import { StatesNgrxService } from 'src/app/services/states-ngrx.service';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';
import { InformationService } from '@services';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() rolSelect: Role = {
    rol: '',
    name: '',
    _idData: '',
    avatar: '',
  };

  isSidebarOpen: boolean = false;
  menuItems: MenuWeb[] | any = [];
  roles: Role[] = [];
  opcion = '';
  expandedOption: string | null = null;
  isUserProfileExpanded = false;
  selectedSubMenu: string | null = null;
  isMobile: boolean = false; // Variable para detectar si estamos en móvil

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private statesNgrxService: StatesNgrxService,
    private swalService: SweetAlertService,
    private infoService: InformationService,
    // private router: Router
  ) {}

  ngOnInit(): void {
    this.dispatchSelectors();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this)); // Escucha el cambio de tamaño de pantalla
  }

  dispatchSelectors() {
    this.store
      .select(rolSession)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((rol) => {
          switch (rol) {
            case 'user':
              return this.infoService.getMenusRoles('menuWebUser');
            case 'doctor':
              return this.infoService.getMenusRoles('menuWebDoctor');
            case 'provider':
              return this.infoService.getMenusRoles('menuWebAdmin');
            default:
              return this.infoService.getMenusRoles('menuWebAdvisor');
          }
        })
      )
      .subscribe((resp: any) => {
        this.menuItems = resp.body;
      });

    this.store
      .select(getRoles)
      .pipe(takeUntil(this.destroy$))
      .subscribe((roles) => {
        this.roles = roles;
      });
  }

  selectSubMenu(subMenuName: string) {
    this.selectedSubMenu = subMenuName;
  }

  toggleSubMenu(option: string) {
    this.expandedOption = this.expandedOption === option ? null : option;
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  toggleUserProfile() {
    this.isUserProfileExpanded = !this.isUserProfileExpanded;
  }

  changeRol(role: Role) {
    this.statesNgrxService.changeRol(role);
    this.swalService.showInfo('', 'Se cambió de perfil a: ' + role.name);
  }

  get getProfileRoleUrl(): string {
    return `/${this.rolSelect.rol}/profile`;
  }

  getProfileRoleName(rol: string): string {
    switch (rol) {
      case 'user':
        return 'Usuario';
      case 'provider':
        return 'Empresa';
      case 'doctor':
        return 'Profesional de salud';
      default:
        return 'Perfil';
    }
  }

  // Método para detectar si la pantalla es pequeña
  checkScreenSize() {
    this.isMobile = window.innerWidth < 1280;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
