import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';

interface EncryptionAction {
  action: string;
  data: any;
  key?: string;
  iv?: string;
}

@Injectable({
  providedIn: 'root'
})

export class EncryptService {

  secretKey = 'S3cR3tK3yBoD1';

  constructor() { }
  

  manageActions(action: EncryptionAction): any {
    switch (action.action) {
      case 'password':
      case 'generateStaticKey':  
        return this.encryptPassword(action.data);
      case 'publicKey':
        return this.encryptByPublicKey(action.data, action.key!);
      // Puedes agregar más casos para otras acciones aquí
      case 'symmetricEncryption': 
        return this.symmetricEncryption(action.data, action.key!);

      case 'symmetricDecryption': 
        return this.symmetricDecryption(action.data, action.key!);

      case 'symmetricEncryptionByIv':
        return this.symmetricEncryptionByIv(action.data, action.key!);

      case 'symmetricDecryptionByIv':
        return this.symmetricDecryptionByIv(action.data, action.key!, action.iv!);

      case 'generateKey': 
        return this.generateKey(action.data);
      default:
        throw new Error('Acción de encriptación no válida');
    }
  }

  // ======================================
  // Método para encriptar texto son sha 256
  // ======================================
  public encryptPassword(pssw: string): string {
    return CryptoJS.SHA512(pssw).toString(CryptoJS.enc.Hex);
  }

  // ======================================
  // Método para encriptar con llave publica
  // ======================================

  // Sin aletoreidad
  public encryptByPublicKey(information: string, key: string) { 

    const publicKey = forge.pki.publicKeyFromPem(key);
    const encrypted = publicKey.encrypt(information);
    const encryptedMessage = forge.util.encode64(encrypted);

    return encryptedMessage;
  }


  // ======================================
  // Método para encriptar de forma simetrica
  // ======================================
  private symmetricEncryption(data: string, key: string) {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  private symmetricDecryption(data: string, key: string) {
      const bytes = CryptoJS.AES.decrypt(data, key);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      return plaintext;
  }

  private symmetricEncryptionByIv(data: string, key: string) {

    const iv = CryptoJS.lib.WordArray.random(16); // Genera un IV aleatorio de 16 bytes
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return {
        encryptData: encrypted.toString(),
        iv: iv.toString(CryptoJS.enc.Base64) // Convierte el IV a Base64 para poder almacenarlo o transmitirlo
    };
  }

  private symmetricDecryptionByIv(data: string, key: string, iv: string): string {
    const decrypted = CryptoJS.AES.decrypt(data, key, { iv: CryptoJS.enc.Base64.parse(iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // ======================================
  // Método para encriptar con llave publica
  // ======================================
  private decrypt(encryptedMessage: string, key: string): string {
    const privateKey = forge.pki.privateKeyFromPem(key);
    const decodeText = forge.util.decode64(encryptedMessage);
    const decrypted = privateKey.decrypt(decodeText);

    return decrypted;
  }

  /**
   * Obtiene el año actual.
   *
   * @returns El año actual en formato numérico.
   */
  private getDate(): number {

    const date = new Date();
    const year = date.getFullYear();
    return year;
  }

  private generateKey(keyData: any) {

    const { patientId, doctorId, type, anio } = keyData;

    let year: number;
    if(anio) year = anio;
    else year = this.getDate();

    const data = `${patientId}*${doctorId}/${type}-*/${year}`;
    const key = this.encryptPassword(data);

    return key;
  }


  
encryptDataBody(data:any): string {
  const jsonString = JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(jsonString, this.secretKey).toString();        
  
  
  
  return encryptedData;
}
decryptDataBody(encryptedData: string): any {
  const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedData);
}
  
}
