import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CellPhone, Company } from '@interfaces/information.interface';
import { ModalService, StatesNgrxService, SweetAlertService } from '@services';
import { AdvisorService } from 'src/app/roles/advisor/services/advisor.service';

@Component({
  selector: 'app-approve-company',
  templateUrl: './approve-company.component.html',
  styleUrls: ['./approve-company.component.scss'],
})
export class ApproveCompanyComponent implements OnInit {
  @Input() data?: any;
  @Input() company!: Company;
  @Input() viewButtons=true;
  @Output() myEvent = new EventEmitter<boolean>();

  public approveForm!: FormGroup;

  userData:any;
  motives:string[] =[]

  view = 'companyView';
  aprobar = {
    companyId: '',
    approved: '',
    whoApproved: '',
    comments: '',
    motives: <string[]>[],
  };

  cellphonesTxt = '';
  servicesArrayTxt = '';

  constructor(
    public modalService: ModalService,
    private statesNgrxService:StatesNgrxService,
    private swalService: SweetAlertService,
    private advisorService:AdvisorService) {
      this.iniciarFormulario();
    }
  ngOnInit(): void {
    if(this.data){
      this.company = this.data.company;

    }

    this.statesNgrxService.getDataRolSelect().subscribe((resp:any)=>{
      this.userData = resp.data;
      this.aprobar.whoApproved = resp._idData;
      this.aprobar.companyId = this.company._id!;
    });

    this.cellphonesTxt = this.getCellPhonesArray(this.company.cellPhones);
    this.servicesArrayTxt = this.getServicesArray(this.company.providerType);
  }

  iniciarFormulario(){
    this.approveForm = new FormGroup({
      comments: new FormControl<string | null>(null),
      motives: new FormControl<string[]>([]),
    })

  }

  changeView(view: string) {
    this.view = view;
  }

  getServicesArray(providerType: any): string {

    let nServices = '';
    const regex = /^[\s,]+|[\s,]+$/g;
    if (providerType.length > 0) {
      for (const type of providerType) {
        const services = type.services as string[];
        services.forEach((service) => {
          nServices = nServices + ', ' + service;
        });
      }
    }
    const cadenaLimpia = nServices.replace(regex, '');
    return cadenaLimpia;
  }

  getCellPhonesArray(cellPhones: CellPhone[]): string {

    let nCellPhones = '';
    const regex = /^[\s,]+|[\s,]+$/g;
    for (const cell of cellPhones) {
      nCellPhones =
        nCellPhones + cell.prefix?.replace(' ', '') + ' ' + cell.phone + ', ';
    }
    const cadenaLimpia = nCellPhones.replace(regex, '');
    return cadenaLimpia;
  }

  arrayEmmiter(data:string[]){
    this.motives = data;
    this.approveForm.controls['motives'].setValue(data);
  }

  hacerScroll(id: string): void {
    // Buscar el elemento por su ID
    var elementos = document.querySelectorAll(`#${id}`)[0] as HTMLElement;

    // Realizar el desplazamiento suave al elemento
    elementos.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  async approveCompany(approved:string) {

    this.aprobar.approved = approved;
    const aprobar= approved=='approved'?'APROBAR':"DENEGAR";

    if (approved==="approved") {

      this.approveForm.controls['comments'].setErrors(null);
      this.approveForm.controls['motives'].setErrors(null);

    } else {

      if (this.approveForm.controls['comments'].value === null || this.approveForm.controls['comments'].value === '') {
        this.approveForm.controls['comments'].setErrors({ require: true });
      }

      if (this.approveForm.controls['motives'].value.length ===0) {
        this.approveForm.controls['motives'].setErrors({ minData: true });
      }
    }

    if (this.approveForm.valid) {
      this.aprobar.approved = approved;
      this.aprobar.motives = this.motives;
      this.aprobar.comments = this.approveForm.value.comments;

      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: `Se va a ${aprobar} la solicitud.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Sí, ${aprobar} la solicitud`,
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.advisorService.postApprovedCompanies(this.aprobar).then((resp:any)=>{
          if (resp.ok) {
            this.myEvent.emit(true);
            this.modalService.closeModal();
          }
        }).catch(err=>{
        });
      }
    } else {
      this.approveForm.markAllAsTouched();
      const requiredFields = [
        'motives',
        'comments',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Para denegar una solicitud, registra los motivos y al menos una justificación',
        'warning'
      );

      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.approveForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }

    }

  }


}
