<div class="parent-doctor">
  <!-- Header -->
  <div class="div4-doctor header">
    <h2>Good Morning, Daniel Bruk</h2>
    <p>Have a nice day at work</p>
  </div>

  <!-- Next Patient -->
  <div class="div1-doctor next-patient">
    <h3>Next Patient</h3>
    <div class="patient-info">
      <img src="https://via.placeholder.com/50" alt="Patient Photo">
      <div>
        <p>Polly Paul</p>
        <span>USG + Consultation</span>
      </div>
    </div>
    <p>10:30 - $50</p>
  </div>

  <!-- Patient Stats -->
  <div class="div3-doctor stats total-patient">
    <div class="stat-box">
      <h4>Total Patient</h4>
      <p>783K</p>
      <span>+6%</span>
    </div>
  </div>

  <div class="div5-doctor stats total-appointment">
    <div class="stat-box">
      <h4>Appointment</h4>
      <p>76</p>
      <span>+4%</span>
    </div>
  </div>

  <div class="div6-doctor stats doctor-count">
    <div class="stat-box">
      <h4>Doctor</h4>
      <p>76</p>
      <span>+4%</span>
    </div>
  </div>

  <div class="div7-doctor stats hospital-earning">
    <div class="stat-box">
      <h4>Hospital Earning</h4>
      <p>$56K</p>
      <span>-4%</span>
    </div>
  </div>

  <!-- Patient Statistics Circle -->
  <div class="div8-doctor patient-stats-circle">
    <h3>Patients</h3>
    <div class="chart-container">
      <p>Total Patients</p>
      <p>412,154 People</p>
      <canvas id="patientChart"></canvas>
    </div>
    <div class="chart-details">
      <p>New: 64</p>
      <p>Recovered: 73</p>
      <p>In Treatment: 48</p>
    </div>
  </div>

  <!-- Patient Details -->
  <div class="div2-doctor patient-details">
    <h3>Patient</h3>
    <div class="patient-card">
      <img src="https://via.placeholder.com/100" alt="Cive Slauw">
      <div>
        <h4>Cive Slauw</h4>
        <p>Check-in: 21 Aug 2020, 12:45 AM</p>
        <p><strong>Male</strong>, Diabetes</p>
      </div>
    </div>
    <p>Address: 795 Folsom Ave, Suite 600, San Francisco, CA 94107</p>
    <p>Phone: +00 123 456 7680</p>
    <p>Email: infoexample.com</p>
  </div>

  <!-- Upcoming Appointments -->
  <div class="div9-doctor upcoming-appointments">
    <h3>Upcoming Appointments</h3>
    <ul>
      <li>
        <p>Shawn Hampton</p>
        <span>Emergency appointment</span>
        <span>10:00</span>
      </li>
      <li>
        <p>Polly Paul</p>
        <span>USG + Consultation</span>
        <span>10:30 - $50</span>
      </li>
      <li>
        <p>John Doe</p>
        <span>Laboratory screening</span>
        <span>11:00 - $70</span>
      </li>
    </ul>
  </div>
</div>

<!-- <div class="parent-provider">
  <div class="div1-provider"> </div>
  <div class="div2-provider"> </div>
  <div class="div3-provider"> </div>
  <div class="div4-provider"> </div>
  <div class="div5-provider"> </div>
  <div class="div6-provider"> </div>
  <div class="div7-provider"> </div>
  <div class="div8-provider"> </div>
  <div class="div9-provider"> </div>
</div> -->
