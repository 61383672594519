import { Component, ElementRef, HostBinding, Input, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { eachMinuteOfInterval, format, parse } from 'date-fns';
import { ApiService, IntrojsService, StatesNgrxService, SweetAlertService } from '@services';

import { ModalService } from 'src/app/reusable-modal/modal.service';
import { ScheduleSetterComponent } from './schedule-setter/schedule-setter.component';
import { InfoShiftScheduleComponent } from './info-shift-schedule/info-shift-schedule.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { SessionState } from 'src/app/pages/session/state/session.reducers';
import { getRolSelect } from 'src/app/pages/session/state/session.selectors';
import { AnimationOptions } from 'ngx-lottie';
import { take } from 'rxjs';

@Component({
  selector: 'schedule-programator',
  templateUrl: './schedule-programator.component.html',
  styleUrls: ['./schedule-programator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleProgramatorComponent {
  @ViewChildren('sundayRef, mondayRef, tuesdayRef, wednesdayRef, thursdayRef, fridayRef, saturdayRef') templateRefs!: QueryList<ElementRef>;

  @Input() minutesStep: number = 60
  @Input() maxHour: string = '16:00'
  @Input() minHour: string = '08:00'

  @HostBinding('style.--hourtSegmentHeight') hourtSegments: string = '100% 48px'
  @HostBinding('style.--taskCardHeight') taskCardHeight: string = '48px';

  options: AnimationOptions = { path: 'assets/lottie-animations/calendar_edited.json' };

  private templateRefMap: { [key: string]: ElementRef } = {};

  branchSelected!: string
  doctorSelected!: string
  companyData!: any

  loader = false

  doctorForm: FormGroup

  subServicesData: any[] = []
  servicesData: any[] = []
  hourRange: any[] = []
  weekDays = [
    {
      "name": "Domingo",
      "abbreviations": {
        "simply": "Dom",
        "letter": "D"
      }
    },
    {
      "name": "Lunes",
      "abbreviations": {
        "simply": "Lun",
        "letter": "L"
      }
    },
    {
      "name": "Martes",
      "abbreviations": {
        "simply": "Mar",
        "letter": "M"
      }
    },
    {
      "name": "Miércoles",
      "abbreviations": {
        "simply": "Mié",
        "letter": "M"
      }
    },
    {
      "name": "Jueves",
      "abbreviations": {
        "simply": "Jue",
        "letter": "J"
      }
    },
    {
      "name": "Viernes",
      "abbreviations": {
        "simply": "Vie",
        "letter": "V"
      }
    },
    {
      "name": "Sábado",
      "abbreviations": {
        "simply": "Sáb",
        "letter": "S"
      }
    }
  ]

  scheduleList: any

  doctorList: any[] = []

  constructor(
    private sessionStore: Store<SessionState>,
    private sweetAlert: SweetAlertService,
    // private introService: IntrojsService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private render: Renderer2,
    private statesNgrxService: StatesNgrxService
  ) {
    this.doctorForm = this.formBuilder.group({ doctorControl: [null, [Validators.required]] })
    // this.branchSelected = '65304c334f5364c6b0872770'
  }

  ngOnInit() {
    // this.generatehours()
    let rollSession$ = this.sessionStore.pipe(select(getRolSelect))
    rollSession$.pipe(take(1)).subscribe((companyData) => this.companyData = companyData)
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.introService.launchTour([
    //     {
    //       element: '#branchSelector',
    //       title: 'Selección de sucursal',
    //       intro: 'Selecciona e intercambia entre las sucursales de tu empresa en donde estableceras el horario... ',
    //       position: 'bottom',
    //     },
    //     {
    //       element: '#doctorSelector',
    //       title: 'Doctor o especialista',
    //       intro: 'Selecciona el doctor al cual se establecerá el horario de atención ...',
    //       position: 'bottom',
    //     },
    //     {
    //       element: '#progcalendar',
    //       title: 'Calendario de atención',
    //       intro: 'En esta sección podras establecer los días que tendras atención para tus clientes...',
    //       position: 'top',
    //     },
    //     {
    //       element: '#progcalendarday',
    //       title: 'Día de la semana',
    //       intro: 'En cada día de la semana podrás establecer el horario en que atenderas a tus pacientes...',
    //       position: 'right',
    //     },
    //     {
    //       element: '.task__list__card',
    //       title: 'Hora de atención',
    //       intro: 'Aquí verás las horas de atención y podras consultar, activar o consultar dicha información',
    //       position: 'bottom',
    //     }
    //   ]);
    // }, 2000);
  }

  changeBranch(event: any) {
    let { branchId } = event
    this.branchSelected = branchId;
    this.initData()
  }

  changeDoctor() {
    this.doctorSelected = this.doctorForm.controls['doctorControl'].value
    this.callAppoiments()
  }

  initData() {
    let servicesUrl = `service/company/${this.companyData._idData}`
    let doctorsBranchUrl = `company/branch-personnel/${this.branchSelected}/doctors`

    this.apiService.getRequest(servicesUrl).subscribe(({ ok, body }) => {
      if (ok) this.servicesData = body
    })

    this.apiService.getRequest(doctorsBranchUrl).subscribe(({ ok, body }) => {
      if (ok) {
        if (!body.length) {
          this.doctorList = [{ name: 'No hay doctores para mostrar', employee: { _id: -1 } }]
          this.doctorForm.controls['doctorControl'].setValue(-1)
          this.doctorForm.controls['doctorControl'].disable()
          this.scheduleList = {
            "_id": "-1",
            "doctorId": "-1",
            "monday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "tuesday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "wednesday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "thursday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "friday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "saturday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "sunday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "scheduleStatus": "active"
          }

          this.templateRefs.forEach((ref: ElementRef) => (ref.nativeElement as HTMLElement).innerHTML = "");

          return
        }

        this.doctorSelected = body[0].employee._id
        this.doctorForm.controls['doctorControl'].setValue(this.doctorSelected)
        this.doctorForm.controls['doctorControl'].enable()

        this.doctorList = body.map((data: any) => {
          let name = `${data.employee.lastNames} ${data.employee.names}`
          return data = { ...data, name }
        })

        this.callAppoiments()
      }
    })
  }

  callAppoiments() {
    this.loader = true
    let schedulesUrl = `schedule/doctor/${this.doctorSelected}`
    this.apiService.getRequest(schedulesUrl).subscribe(({ ok, body }) => {
      if (!ok) return
      this.scheduleList = body[0];
      let { maxHours, minHours } = this.scheduleList;

      this.maxHour = maxHours;
      this.minHour = minHours;

      this.generatehours()

      setTimeout(() => {
        this.templateRefs.forEach((ref: ElementRef) => {
          (ref.nativeElement as HTMLElement).innerHTML = ""
          this.templateRefMap[(ref.nativeElement as HTMLElement).id] = ref
        });
        this.validateDays()
      }, 1000);
    })
  }

  generatehours() {
    let minDate = parse(this.minHour, 'HH:mm', new Date())
    let maxDate = parse(this.maxHour, 'HH:mm', new Date())

    let range = eachMinuteOfInterval({ start: minDate, end: maxDate }, { step: this.minutesStep })
    let times = range.map((date) => format(date, 'hh:mm a'))

    this.hourRange = times
    this.hourtSegments = `100% calc(100% / ${this.hourRange.length - 1})`
    this.taskCardHeight = `calc(100% / ${this.hourRange.length - 1} - 1px)`
  }

  validateDays() {
    this.generateShifts(this.scheduleList.sunday, 'sundayRef')
    this.generateShifts(this.scheduleList.monday, 'mondayRef')
    this.generateShifts(this.scheduleList.tuesday, 'tuesdayRef')
    this.generateShifts(this.scheduleList.wednesday, 'wednesdayRef')
    this.generateShifts(this.scheduleList.thursday, 'thursdayRef')
    this.generateShifts(this.scheduleList.friday, 'fridayRef')
    this.generateShifts(this.scheduleList.saturday, 'saturdayRef')

    setTimeout(() => this.loader = false, 300);
  }

  addShift(shift: any, day: string) {
    let { dayStatus } = shift;

    if (dayStatus === 'blocked') {
      this.sweetAlert.lauchSwal('¡No permitido!', 'La sucursal debe tener al menos un doctor para poder establecer el horario', 'info')
      return
    }

    let scheduleSetterInstance = this.modalService.openModal(ScheduleSetterComponent, {
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      modalClass: 'modal-dialog modal-lg',
      dataKeyboard: false,
      static: 'static',
      footer: false,
      header: false,
    }, {
      doctorId: this.doctorForm.controls['doctorControl'].value,
      companyId: this.companyData._idData,
      subservices: this.subServicesData,
      scheduleList: this.scheduleList,
      branchId: this.branchSelected,
      services: this.servicesData,
      rangeHours: this.hourRange,
      shifts: shift.shifts,
      day
    })

    scheduleSetterInstance.onCloseScheduleSetter.subscribe(() => {
      this.callAppoiments()
      setTimeout(() => this.statesNgrxService.updateGlobalUser(), 1000)
    });
  }

  showShiftInformation(shift: any, hour: any, day: any, event: Event) {
    let doctor = this.doctorList.filter((doc) => doc.employee._id === this.doctorSelected)[0]

    let infoScheduleInstance = this.modalService.openModal(InfoShiftScheduleComponent, {
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      modalClass: 'modal-dialog modal-md',
      dataKeyboard: false,
      static: 'static',
      footer: false,
      header: false,
    }, {
      scheduleList: this.scheduleList,
      rangeHours: this.hourRange,
      company: this.companyData,
      shiftList: [],
      shift: shift,
      hour: hour,
      day: day,
      doctor
    })

    event.stopPropagation()

    infoScheduleInstance.onCloseInfoSchedule.subscribe(() => this.callAppoiments())
  }

  generateShifts(dayShift: any, day: string) {
    let { dayStatus, shifts } = dayShift
    let shiftsDay: any[] = []
    dayStatus === 'active' && shifts.forEach((shift: any) => shiftsDay.push(this.generateHourByShifts(shift, day)));

    if (!shiftsDay.length) return

    let shiftsOut = shiftsDay.flat()

    this.templateRefMap[day]

    shiftsOut?.forEach(shift => this.render.appendChild(this.templateRefMap[day].nativeElement, shift))
  }

  generateHourByShifts(shift: any, day: string) {
    let { shiftStatus, branch, office, hours, _id, createdAt, updatedAt } = shift
    let shiftsHOurs: any[] = []

    hours.forEach((hour: any) => {
      let { height, position } = this.getHeightAndPosition(hour)
      let taskContainer = this.createRenderElement({ type: 'div', classes: ['task__list__card', shiftStatus], styles: { height, top: position }, clickAction: (ev: Event) => this.showShiftInformation(shift, hour, day, ev), attributes: { title: 'Ver informacion de horario' } })

      // let labelText = `${office}<br>${this.getNormalHour(hour.start)} - ${this.getNormalHour(hour.end)}` // TODO finish

      let labelTask = this.createRenderElement({ type: 'span', classes: ['office', 'ellipsis__text'], content: office, attributes: { title: office } })
      taskContainer.appendChild(labelTask)

      let iconTask = this.createRenderElement({ type: 'i', classes: ['fa', 'fa-clock-o'] })
      // taskContainer.appendChild(iconTask)

      let normalHours = `${this.getNormalHour(hour.start)} - ${this.getNormalHour(hour.end)}`
      let labelHours = this.createRenderElement({ type: 'span', classes: ['hour', 'ellipsis__text'], content: `${normalHours}`, attributes: { title: normalHours } })
      taskContainer.appendChild(labelHours)

      // <span class="hour">09:00 - 11:00</span>
      shiftsHOurs.push(taskContainer)
    })

    return shiftsHOurs
  }

  getHeightAndPosition(hour: { start: string, end: string }) {
    let { start, end } = hour
    let startDateTmp = this.getNormalHour(start)
    let endDateTmp = this.getNormalHour(end)
    let idxStart = this.hourRange.findIndex(hrr => hrr == startDateTmp)
    let idxEnd = this.hourRange.findIndex(hrr => hrr == endDateTmp)

    return { height: `calc((100% / ${this.hourRange.length - 1} - 1px) * ${idxEnd - idxStart})`, position: `calc((100% / ${this.hourRange.length - 1}) * ${idxStart} + 2px)` }
  }

  getNormalHour(hour: string | number) {
    return format(parse(`${hour}:00`, 'HH:mm', new Date()), 'hh:mm a')
  }

  private createRenderElement(options: IRenderElementOptions): HTMLElement {
    const { type, classes, content, clickAction, attributes, directives, styles } = options
    const tmpElement = this.render.createElement(type)

    if (content) {
      const tmpTextElement = this.render.createText(content)
      this.render.appendChild(tmpElement, tmpTextElement)
    }

    clickAction && this.render.listen(tmpElement, 'click', (event: Event) => clickAction(event))

    classes?.length && classes.forEach(cls => this.render.addClass(tmpElement, cls))

    if (attributes) {
      for (const attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          const attrValue = attributes[attrName];
          this.render.setAttribute(tmpElement, attrName, attrValue);
        }
      }
    }

    if (styles) {
      for (const styleName in styles) {
        if (styles.hasOwnProperty(styleName)) {
          const styleValue = styles[styleName];
          this.render.setStyle(tmpElement, styleName, styleValue);
        }
      }
    }

    // directives && directives.forEach(({ directiveClass, entries }) => {
    //   let tmpDirective = new directiveClass()
    //   // console.debug(tmpDirective);
    //   tmpDirective.el = new ElementRef(tmpElement)
    //   tmpDirective.componentToRender = DialogPopupComponent
    //   tmpDirective.renderer = this.render
    //   tmpDirective.viewCRef = this.tooltipRef
    //   // ViewContainerRef

    //   tmpDirective.init()
    // });

    return tmpElement
  }
}

interface IRenderElementOptions {
  // attrType?: string;
  // attrValue?: string;
  attributes?: any;
  classes?: string[];
  clickAction?: Function;
  content?: any;
  directives?: IDirectiveOpts[];
  styles?: any;
  type: string;
}

interface IDirectiveOpts {
  directiveClass: any,
  entries?: any[]
}
