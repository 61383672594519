import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { DatePipe } from '@angular/common';


// Modulos
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';

import { AppComponent } from './app.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { AuthModule } from './auth/auth.module';
import { InfoNoticiasModule } from './info-noticias/info-noticias.module';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';

// Social Login
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { SearchBoxComponent } from './shared-components/search-box/search-box.component';
// import { ReusableModalModule } from './reusable-modal/reusable-modal.module';
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { PipesModule } from './pipes/pipes.module';

//Estado de cita
import { appointmentReducer } from './directory/components/state/appointment.reducer';
import { appointmentFeatureKey } from './directory/components/state/appointment.selectors';




const config: SocketIoConfig = {
  url: environment.apiUrl,
  options: {
    transports: ['websocket'],
    reconnectionAttempts: 100,  // Reconnection attempts before giving up.
    reconnectionDelay: 2000,    // Delay before reconnection (milliseconds)
    rememberUpgrade: true, //
    reconnection: true,         // Enable reconnection from socket
    autoConnect: true,         // Disabled to manually connect on login component
    upgrade: false
  }
};

@NgModule({
  declarations: [AppComponent, NopagefoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    SearchBoxComponent,
    PagesModule,
    AuthModule,
    PipesModule,
    SharedComponentsModule,
    InfoNoticiasModule,
    StoreModule.forRoot({
      [appointmentFeatureKey]: appointmentReducer
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    SocketIoModule.forRoot(config),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    DatePipe,
    BrowserModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '47585566989-2b3d5f12a0e6gvjm58uroectitd8rbpd.apps.googleusercontent.com',
              { oneTapEnabled: false }
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID, useValue: 'es-CO'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeEs)
  }
}
