import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SessionState, sessionStateFeatureKey } from "./session.reducers";

const sessionState = createFeatureSelector<SessionState>(sessionStateFeatureKey);

export const getMenu = createSelector(
  sessionState,
  (state) => state.menu
); 

export const getRoles = createSelector(
  sessionState,
  (state) => state.roles 
);

export const getRolSelect = createSelector(
  sessionState,
  (state) => state.rolSelect 
);

export const dataId = createSelector(
  sessionState,
  (state) => state.rolSelect?._idData
);

export const typeOfProviver = createSelector(
  sessionState,
  (state) => state.typeOfProviver
);

export const rolSession = createSelector(
  sessionState,
  (state) => state.rolSelect?.rol
)